import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { connect } from "formik";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fullName } from "utils/common";
import { FormContext } from "../../../components/FormContext";

function SingleItem({ title }) {
  return (
    <Typography
      component="h4"
      fontWeight="700"
      sx={{ fontSize: { sx: "12px", md: "24px" } }}
    >
      {title}
    </Typography>
  );
}

function ItemWithBackButton({ title, link, backBtn }) {
  const naviagate = useNavigate();
  return (
    <>
      <IconButton
        size="small"
        sx={{ mr: 1, color: "text.primary" }}
        onClick={() => {
          if (backBtn) {
            backBtn();
          } else {
            naviagate(link);
          }
        }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>
      <Typography
        component="h4"
        fontWeight="700"
        sx={{ fontSize: { sx: "12px", md: "24px" }, cursor: "pointer" }}
      >
        {" "}
        {title}
      </Typography>
    </>
  );
}

function ItemWithButtons({ title, link, btn, onClick, backBtn }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          sx={{ mr: 1, color: "text.primary" }}
          onClick={() => {
            if (backBtn) {
              backBtn();
            } else {
              navigate(link, { state: location.pathname });
            }
          }}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Typography
          component="h4"
          fontWeight="700"
          sx={{
            fontSize: { sx: "12px", md: "24px" },
            cursor: "pointer",
          }}
        >
          {" "}
          {title}
        </Typography>
      </Stack>
      <Stack>
        <Button color="primary" variant="contained" onClick={onClick}>
          {" "}
          {btn}
        </Button>
      </Stack>
    </Stack>
  );
}

const pathName = {
  "/fitness/programs": "Programmes d’entraînements",
  "/fitness/exercices": "Bibliothèque d’exercices",
  "/fitness/sessions": "Exercise Sessions",
  "/nutrition/recipes": "Bibliothèque de recettes",
  "/nutrition/programs": "Nutrition Plans",
  "/clients": "Clients Evodream",
};

const NavHeader = ({ location }) => {
  const dispatch = useDispatch();
  const { handleSubmit } = useContext(FormContext);
  const formData = useSelector((state) => state.programs.formData);
  const nutrition = useSelector((state) => state.nutritions.nutritionData);
  const client = useSelector((state) => state.clients.currentClientDetails);

  const { id } = useParams();

  let item = null;
  if (
    location === "/fitness/programs" ||
    location === "/fitness/exercices" ||
    location === "/fitness/sessions" ||
    location === "/nutrition/recipes" ||
    location === "/nutrition/programs" ||
    location === "/clients"
  ) {
    item = <SingleItem title={pathName[location]} />;
  }
  if (location.includes("/nutrition/programs/")) {
    if (
      location === "/nutrition/programs/final" ||
      location.includes("/nutrition/programs/final/")
    ) {
      item = (
        <ItemWithButtons
          link={
            location.includes("/nutrition/programs/final/")
              ? `/nutrition/programs/${id}`
              : "/nutrition/programs/create"
          }
          title={nutrition.name ? nutrition.name : "Programme name"}
          btn="Save Nutrtion Plan"
          onClick={() => handleSubmit()}
        />
      );
    } else {
      item = (
        <ItemWithBackButton
          link="/nutrition/programs"
          title={nutrition.name ? nutrition.name : "Programme name"}
        />
      );
    }
  }
  if (location.includes("/fitness/exercices/")) {
    item = (
      <ItemWithBackButton link="/fitness/exercices" title="Créer un exercice" />
    );
  }
  if (location.includes("/fitness/sessions/")) {
    item = (
      <ItemWithButtons
        link="/fitness/sessions"
        title="Create Exercise Session"
        btn="Save Session"
        onClick={() => handleSubmit()}
      />
    );
  }
  if (location.includes("/clients/")) {
    item = (
      <ItemWithBackButton
        link="/clients"
        title={fullName(client.first_name, client.last_name)}
      />
    );
  }
  if (location.includes("/fitness/programs/")) {
    if (
      location === "/fitness/programs/final" ||
      location.includes("/fitness/programs/final/")
    ) {
      item = (
        <ItemWithButtons
          link={
            location.includes("/fitness/programs/final/")
              ? `/fitness/programs/${id}`
              : "/fitness/programs/create"
          }
          title={formData.name ? formData.name : "Programme name"}
          btn="Save Workout Program"
          onClick={() => handleSubmit()}
        />
      );
    } else {
      item = (
        <ItemWithBackButton
          link="/fitness/programs"
          title={formData.name ? formData.name : "Créer un programme"}
        />
      );
    }
  }

  if (location.includes("/nutrition/recipes/")) {
    item = (
      <ItemWithButtons
        link="/nutrition/recipes"
        title="Créer une recette"
        btn="ENREGISTRER LA RECETTE"
        onClick={() => handleSubmit()}
      />
    );
  }

  return item;
};

export default connect(NavHeader);
