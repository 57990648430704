import { Button, MenuItem } from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import Field from "components/Field";
import { multiSelect, required } from "components/Field/validate";
import { connect } from "formik";
import { useSelector } from "react-redux";
import { muscle } from "../../../utils/gym.utils";

const DetailProgram = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const programms = useSelector((state) => state.programs.programms);

  const renderSuviants = (programms || []).map((ele) => (
    <MenuItem value={ele["_id"]} key={ele["_id"]}>
      {ele.name}
    </MenuItem>
  ));

  return (
    <>
      <CardImageUploader
        name="thumbnail"
        label={
          values.thumbnail ? "CHANGER LA MINIATURE" : "AJOUTER UNE MINIATURE"
        }
        apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
        thumbnail={values ? values.thumbnail : null}
      />
      <Field
        variant="outlined"
        type="text"
        label="TITRE DU PROGRAMME"
        placeholder="Entrer un titre"
        name="name"
        validate={required}
      />
      <Field
        type="multiselect"
        label="MUSCLE PRIMAIRE"
        placeholder="Sélectionner un muscle"
        name="primary_muscle"
        notched={true}
        validate={multiSelect}
        options={muscle}
      />
      <Field
        type="multiselect"
        label="MUSCLE SECONDAIRE"
        placeholder="Sélectionner un muscle"
        name="secondary_muscle"
        validate={multiSelect}
        notched={true}
        options={muscle}
      />
      <Field
        variant="outlined"
        type="text"
        label="DESCRIPTION"
        name="description"
        placeholder="Entrer une description"
        multiline
        validate={required}
        rows={4}
      />
      <Field
        type="select"
        label="DÉFINIR LE PROGRAMME SUIVANT"
        placeholder="Sélectionner un programme"
        name="next_program_id"
        notched={true}
      >
        <MenuItem
          value=""
          sx={{
            justifyContent: "end",
          }}
        >
          <Button onClick={() => setFieldValue("next_program_id", "")}>
            Clear
          </Button>
        </MenuItem>
        {renderSuviants}
      </Field>
    </>
  );
};

export default connect(DetailProgram);
