import React from 'react';
import {Stack, Typography} from "@mui/material";

const TitleHeader = ({number,title}) => {
    return (
        <Stack mb={3}>
            <Typography
                component="div"
                display="flex"
                alignItems="center"
                color="#999999"
                gap="13px"
                fontSize="18px"
            >
                {number}
                <Typography
                    color="#222222"
                    fontWeight="600"
                    pl={2}
                    borderLeft="2px solid #D9D9D9"
                    lineHeight="10px"
                >
                    {title}
                </Typography>
            </Typography>
        </Stack>
    );
};

export default TitleHeader;
