import React from 'react';
import Field from "components/Field";

const RecipeDescription = () => {
    return (
        <Field
            type="list"
            name={`steps`}
            size="small"
            mb={0}
        />
    );
};

export default RecipeDescription;
