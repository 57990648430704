import React from 'react';
import {Button, InputAdornment, TextField,Stack} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmationModel from "components/ConfirmationModel";
import {useDispatch, useSelector} from "react-redux";
import {toggleSwapExerciseModel} from "store/actions/program";

const AddVariableModel = () => {
    const dispatch = useDispatch();
    const isOpenSwapExerciseModel = useSelector((state => state.programs.isOpenSwapExerciseModel));
    return (
        <ConfirmationModel
            showActions={false}
            isOpen={isOpenSwapExerciseModel}
            title="Ajouter une variable d'exercice"
            maxWidth="sm"
            body={
                <Stack gap={2} >
                    <TextField
                        placeholder="Search exercises"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small"/>
                                </InputAdornment>
                        }}
                    />
                    <Stack direction="row" justifyContent="end">
                        <Button variant="contained">
                            + Add another exercise
                        </Button>
                    </Stack>
                </Stack>
            }
            handleClose={() =>dispatch(toggleSwapExerciseModel())}
        />
    );
};

export default AddVariableModel;
