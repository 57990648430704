import {
    Avatar,
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "utils/hooks/useDocumentTitle";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: "center",
    boxShadow: "0px 8px 40px 0px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
}));
const Welcome = () => {
    useDocumentTitle("Bienvenue sur Evodream Coach");
    const navigate = useNavigate();
    const redirectTo = (link) => {
        navigate(link);
    };
    return (
        <Container maxWidth="xxl">
            <Box pb={4} sx={{ flexGrow: 1 }}>
                <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    mb={2}
                    style={{ fontWeight: 700 }}
                >
                    Content de vous revoir !
                </Typography>

                <Typography variant="body1" mb={5} align="center">
                    Que souhaitez vous créer ?
                </Typography>
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={10} md={5}>
                        <Item onClick={() => redirectTo("/fitness/programs")}>
                            <Avatar
                                sx={{
                                    background:
                                        "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                                    width: 56,
                                    height: 56,
                                    margin: "0 auto",
                                }}
                            >
                                <img src="/assets/logo/fire.svg" alt="fire" />
                            </Avatar>
                            <Typography
                                mt={2}
                                variant="body2"
                                style={{ fontWeight: 600 }}
                            >
                                Programme d’entraînement
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={10} md={5}>
                        <Item onClick={() => redirectTo("/nutrition/programs")}>
                            <Avatar
                                sx={{
                                    background:
                                        "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                                    width: 56,
                                    height: 56,
                                    margin: "0 auto",
                                }}
                            >
                                <img
                                    src="/assets/logo/sticksIcon.svg"
                                    alt="sticksIcon"
                                />
                            </Avatar>
                            <Typography
                                mt={2}
                                variant="body2"
                                style={{ fontWeight: 600 }}
                            >
                                Programme de nutrition
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
export default Welcome;
