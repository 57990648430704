import { Grid, InputAdornment, MenuItem } from "@mui/material";
import Field from "components/Field";
import { multiSelect, required } from "components/Field/validate";
import { connect } from "formik";
import usePrompt from "utils/hooks/usePrompt";
import { recipes } from "utils/recipe.utils";
import CardImageUploader from "../../CardImageUploader";

const RecipePreview = ({ formik }) => {
  const { values } = formik;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardImageUploader
            name="thumbnail"
            label={
              values?.thumbnail
                ? "CHANGER LA MINIATURE"
                : "AJOUTER UNE MIGNIATURE"
            }
            thumbnail={values ? values.thumbnail : null}
            apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardImageUploader
            name="video"
            isVideo={true}
            label={values?.video ? "CHANGER LA VIDEO" : "AJOUTER UNE VIDEO"}
            apiKey="/api/upload/coach/videos/exercises/new_video"
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 3, xs: 0 }}>
        <Grid item xs={12} md={9}>
          <Field
            type="text"
            label="Titre de la recette"
            name="name"
            size="small"
            validate={required}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            type="select"
            label="SELECTIONNER LA DIFFICULTÉ"
            name="difficulty_level"
            size="small"
            notched={true}
            validate={required}
          >
            <MenuItem value="Facile">Facile</MenuItem>
            <MenuItem value="Moyen">Moyen</MenuItem>
            <MenuItem value="Difficile">Difficile</MenuItem>
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 3, xs: 1 }}>
        <Grid item md={3} xs={12}>
          <Field
            type="select"
            label="Sélectionner tous les types de repas"
            name="meal_type"
            size="small"
            notched={true}
          >
            <MenuItem value={`Petit déjeuner`}>Petit déjeuner</MenuItem>
            <MenuItem value={`Déjeuner`}>Déjeuner</MenuItem>
            <MenuItem value={`Dîner`}>Dîner</MenuItem>
            <MenuItem value={`Collation`}>Collation</MenuItem>
          </Field>
        </Grid>
        <Grid item md={3} xs={12}>
          <Field
            type="select"
            label="Montant de la portion"
            notched={true}
            name="serving"
            size="small"
            validate={required}
          >
            {Array(6)
              .fill(1)
              .map((ele, index) => (
                <MenuItem value={index + 1} key={index}>
                  {index + 1} {index === 0 ? `portion` : `portions`}
                </MenuItem>
              ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={3} sm={6}>
          <Field
            type="number"
            label="TEMPS DE PREPARATION"
            name="prep_time"
            size="small"
            validate={required}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} sm={6}>
          <Field
            type="number"
            label="TEMPS DE CUISSON"
            name="cook_time"
            size="small"
            validate={required}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      {/* <Field
                type="text"
                label="FILTRES"
                name="tags"
                size="small"
                validate={required}
                helperText="Separate with comma"
            /> */}
      <Field type="switch" label="High Calories" name="high_calories" />
      <Field
        type="switch"
        label="Ajouter le programme à l’Explorer"
        name="in_explorer"
      />
      {values.in_explorer && (
        <>
          <Field
            type="multiselect"
            label="SELECT EXPLORE PAGE CATEGORY"
            name="explore"
            size="small"
            validate={multiSelect}
            notched={true}
            options={recipes.categorysList}
          />
          <Field
            type="switch"
            label="Available to users with free Trial"
            name="free"
          />
        </>
      )}
    </>
  );
};

export default connect(RecipePreview);
