import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddVariableModel from "components/CreateProgramPage/AddVariableModel";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import AddRecipeModel from "../AddRecipeModel";
import ExerciseCard from "./ExerciseCard";
import ExerciseDetailModel from "./ExerciseDetailModel";

const ExerciseNutrition = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const [current, setCurrent] = React.useState(null);
  const [addExercises, setAddExercisesModel] = useState(false);
  const [open, setOpen] = React.useState([]);

  const recipesLists = useSelector((state) => state.recipes.searchedRecipes);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  const addGroup = () => {
    let exercisesList = activeElement ? activeElement["meals"] : [];
    const newElement = {
      name: "",
      recipes: [],
    };
    setOpen((prev) => [...prev, (exercisesList || []).length]);
    setFieldValue(whereTo + `.meals`, [...exercisesList, newElement]);
  };

  const deleteGroup = (index = null) => {
    let exercisesList = activeElement ? activeElement["meals"] : [];

    let data = [...exercisesList];
    data.splice(index, 1);
    setFieldValue(whereTo + `.meals`, [...data]);
  };

  const toggleCollapse = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const toggleExerciseModel = (response = null) => {
    setCurrent(response);
    setAddExercisesModel(!addExercises);
  };

  const renderExercisesLists = useMemo(() => {
    let list = [];
    const meals = activeElement ? (activeElement.meals || []).length : 0;
    for (let index = 0; index < meals; index++) {
      const recipes = activeElement.meals[index]
        ? activeElement.meals[index].recipes
        : [];
      const elements = _.isEmpty(recipesLists)
        ? []
        : _.map(recipes, (_id) => {
            return _.find(recipesLists, { _id: _id });
          });
      const exercisesList = elements ? elements : [];
      list.push(
        <Draggable draggableId={`recipegroup_${index}`} index={index}>
          {(provided) => (
            <Grid
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              container
              my={3}
              columnSpacing={1}
              alignItems="center"
              key={index}
              sx={{
                background: "#F1F1F1",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              {!_.isEmpty(exercisesList) && (
                <Grid
                  item
                  xs={12}
                  md={1}
                  display="flex"
                  sx={{
                    "&.MuiGrid-item": {
                      paddingLeft: "0px",
                    },
                  }}
                >
                  <AvatarGroup max={2}>
                    {(exercisesList || []).map((img, index) => (
                      <Avatar
                        alt="Remy Sharp"
                        key={index}
                        src={img.thumbnail}
                      />
                    ))}
                  </AvatarGroup>
                </Grid>
              )}
              <Grid
                item
                md={_.isEmpty(exercisesList) ? 11 : 10}
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                <Field
                  type="text"
                  placeholder="Ex. Breakfast"
                  name={whereTo + `.meals[${index}].name`}
                  mb={0}
                  size="small"
                  selectsx={{ background: "white", borderRadius: "10px" }}
                />
              </Grid>
              <Grid item md={1} alignItems="center">
                <Stack direction="row" justifyContent="space-between">
                  <IconButton onClick={() => deleteGroup(index)}>
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => toggleCollapse(index)}>
                    {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Stack>
              </Grid>
              <Collapse
                in={open.includes(index)}
                timeout="auto"
                sx={{ width: "100%", mt: 3 }}
                unmountOnExit
              >
                <ExerciseCard index={index} exercisesList={exercisesList} />

                <Button
                  sx={{
                    width: "100%",
                    mt: 2,
                    fontSize: "12px",
                    borderRadius: "20px",
                    textTransform: "none",
                    background: "#E7E7E7",
                  }}
                  onClick={() => toggleExerciseModel(index)}
                >
                  <span
                    style={{
                      border: "1px dashed #999999",
                      display: "block",
                      width: "100%",
                      borderRadius: "20px",
                      padding: "3px",
                    }}
                  >
                    + Add another recipe
                  </span>
                </Button>
              </Collapse>
            </Grid>
          )}
        </Draggable>
      );
    }
    return list;
  }, [activeElement, open]);
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceRecipeGroupIndex = parseInt(source.droppableId.split("_")[1]);
    const destinationRecipeGroupIndex = parseInt(
      destination.droppableId.split("_")[1]
    );
    if (source.droppableId === destination.droppableId) {
      if (result.type === "droppableSubItem") {
        const items = reorder(
          activeElement["meals"][sourceRecipeGroupIndex].recipes,
          result.source.index,
          result.destination.index
        );

        setFieldValue(whereTo + `.meals[${sourceRecipeGroupIndex}].recipes`, [
          ...items,
        ]);
      }
      if (result.type === "droppableItem") {
        keepListItemInExistingState(source, destination);
        const items = reorder(
          activeElement["meals"],
          result.source.index,
          result.destination.index
        );

        setFieldValue(whereTo + ".meals", items);
      }
    } else {
      const sourceRecipes =
        activeElement["meals"][sourceRecipeGroupIndex].recipes;
      const destinationRecipes =
        activeElement["meals"][destinationRecipeGroupIndex].recipes;
      moveItemBetweenList(
        sourceRecipes,
        destinationRecipes,
        source,
        destination,
        sourceRecipeGroupIndex,
        destinationRecipeGroupIndex
      );
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const keepListItemInExistingState = (source, destination) => {
    const sourceItemIndex = _.indexOf(open, source.index);
    const destinationItemIndex = _.indexOf(open, destination.index);
    if (sourceItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, destination.index);
      setOpen(open);
    } else if (destinationItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, source.index);
      setOpen(open);
    } else {
      const updatedOpen = _.map(open, (o) => {
        if (destination.index < o) {
          o++;
        } else if (destination.index > o) {
          o--;
        }
        return o;
      });
      setOpen(updatedOpen);
    }
  };

  const moveItemBetweenList = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    sourceRecipeGroupIndex,
    destinationRecipeGroupIndex
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    if (_.indexOf(destClone, removed) !== -1) {
      toast.error("La recette existe déjà");
      return;
    }
    destClone.splice(droppableDestination.index, 0, removed);
    setFieldValue(
      whereTo + `.meals[${sourceRecipeGroupIndex}].recipes`,
      sourceClone
    );
    setFieldValue(
      whereTo + `.meals[${destinationRecipeGroupIndex}].recipes`,
      destClone
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box py={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            component="h2"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Poppins"
            pr={2}
          >
            Repas du jour
          </Typography>
        </Stack>
        <Droppable
          droppableId={`nutritionlist_${activeDay}`}
          type="droppableItem"
        >
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {renderExercisesLists}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Button
          sx={{
            width: "100%",
            mt: 2,
            background:
              "linear-gradient(260.66deg, rgba(119, 66, 233, 0.08) 7.06%, rgba(199, 54, 235, 0.08) 92.94%)",
          }}
          onClick={addGroup}
        >
          {" "}
          + ADD MEAL GROUP
        </Button>
        <ExerciseDetailModel />
        <AddVariableModel />
        {addExercises && (
          <AddRecipeModel
            title="Add Recipe"
            isOpen={addExercises}
            current={current}
            toggle={toggleExerciseModel}
          />
        )}
      </Box>
    </DragDropContext>
  );
};

export default connect(ExerciseNutrition);
