import _ from "lodash";
export function filterSets(arr, num) {
  let result = [];
  for (let item of arr) {
    let sets = item.sets.slice(0, num);
    let filteredItem = {
      exercise_id: item.exercise_id,
      sets: sets,
    };
    result.push(filteredItem);
  }
  return result;
}

export function createOrOverrideElement(array1, array2) {
  let list = [];
  for (let i = 0; i < array1.length; i++) {
    const elementToUpdate = array2[i];
    list.push({
      ...array1[i],
      ...elementToUpdate,
    });
  }

  return list;
}

export function createArrayOfElements(n) {
  const emptyExercise = {
    weight: "",
    reps: "",
    rest: 0,
    duration: 0,
  };

  const arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(Object.assign({}, emptyExercise));
  }

  return arr;
}

export function replaceArrayObjValues(arr, from, to, skipKeys) {
  if (arr.length > 0) {
    return _.map(arr, (obj) =>
      _.mapValues(obj, (v, key) => {
        if (skipKeys && _.includes(skipKeys, key)) {
          return v;
        } else {
          return v === from ? to : v;
        }
      })
    );
  }
  return arr;
}
