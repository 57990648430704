import axios from "axios";
import toast from "react-hot-toast";
export const GET_SESSIONS_DATA = "get_sessions_data";
export const getSessions = () => {
  return (dispatch) => {
    dispatch(loadingSessions(true));
    axios
      .get("/api/coach/sessions?independent_session=true")
      .then((res) => {
        dispatch({
          type: GET_SESSIONS_DATA,
          payload: res.data.data,
        });
        dispatch(loadingSessions(false));
      })
      .catch(() => {
        toast.error("Having issue with fetching Sessions Data");
        dispatch(loadingSessions(false));
      });
  };
};

export const PAGINATION_SESSIONS_DATA = "pagination_sessions_data";
export const changePagination = (response = 1) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_SESSIONS_DATA,
      payload: response,
    });
  };
};

export const SEARCH_SESSIONS_DATA = "search_sessions_data";
export const searchSessions = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().sessions.searchedSessions || []).filter(
      (e) => e.name?.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_SESSIONS_DATA,
      payload: filteredItem,
    });
  };
};

export const LOADING_SESSIONS_DATA = "loading_sessions_data";
export const loadingSessions = (response = false) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SESSIONS_DATA,
      payload: response,
    });
  };
};

export const deleteSessionById = (sessionId = "", toggleDeleteModel) => {
  return (dispatch, getState) => {
    axios
      .delete(`/api/coach/sessions/${sessionId}`)
      .then(() => {
        toast.success("Session deleted successfully.");
        dispatch(getSessions());
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
        toast.error("There is some problem in deleting session.");
      });
  };
};
