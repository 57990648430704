import axios from "axios";
import history from "components/history";
import _ from "lodash";
import { toast } from "react-hot-toast";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import { objectFit } from "../../utils/gym.utils";

export const LOADING_NUTRITIONS_DATA = "loading_nutritions_data";
export const loadingNutritions = (response = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_NUTRITIONS_DATA,
      payload: response,
    });
  };
};

export const SET_CURRENT_NUTRITION = "set_current_nutrition";
export const setCurrentNutrition = (values = { weeks: { 1: {} } }) => {
  return {
    type: SET_CURRENT_NUTRITION,
    payload: values,
  };
};

export const GET_INITIAL_NUTRITION = "get_initial_nutrition";
export const getInitialNutrition = (total = null) => {
  return (dispatch, getState) => {
    const totalWeek = total ? total : getState().nutritions.totalWeek;
    let list = [];
    for (let i = 0; i <= 6; i++) {
      list.push({
        desciption: "",
        thumbnail: "",
        meals: [{ name: "", recipes: [] }],
      });
    }
    const weeks = {};
    for (let i = 0; i <= totalWeek - 1; i++) {
      weeks[i] = list;
    }
    dispatch({
      type: GET_INITIAL_NUTRITION,
      payload: { week: { ...weeks } },
    });
  };
};

export const PAGINATE_NUTRITION_DATA = "paginate_nutrition_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATE_NUTRITION_DATA,
      payload: response,
    });
  };
};

export const SET_NUTRITION_DATA = "set_nutrition_data";
export const setNutritionData = (response = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_NUTRITION_DATA,
      payload: response,
    });
  };
};

export const SEARCH_NUTITIONS_DATA = "search_nutitions_data";
export const searchNutritions = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().nutritions.searchedData || []).filter(
      (e) => e.name.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_NUTITIONS_DATA,
      payload: filteredItem,
    });
  };
};

export const GET_NUTRITIONS_DATA = "get_nutritions_data";
export const getNutritions = () => {
  return (dispatch, getState) => {
    dispatch(loadingNutritions(true));
    axios
      .get("/api/coach/nutrition_programs")
      .then((res) => {
        dispatch(loadingNutritions(false));
        dispatch({
          type: GET_NUTRITIONS_DATA,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        toast.error("having issue with fetching Exercise Data");
        dispatch(loadingNutritions(false));
      });
  };
};

export const deleteNutrition = (id) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/nutrition_programs/${id}`)
      .then((res) => {
        dispatch(getNutritions());
        toast.success("Nutrition Deleted Succssfully.");
      })
      .catch((err) => {
        toast.error("Error while Deleting Nutrition");
      });
  };
};

export const SET_ACTIVE_NUTRITION_WEEK = "set_active_nutrition_week";
export const setActiveWeekNutrition = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_NUTRITION_WEEK,
      payload: response,
    });
  };
};

export const SET_ACTIVE_DAY = "set_active_day";
export const setActiveDay = (response = 0) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_DAY,
      payload: response,
    });
  };
};

export const SET_TOTAL_WEEK_NUTRITION = "set_total_week_nutrition";
export const duplicateThisWeek = (formikRef, active = 1) => {
  return (dispatch, getState) => {
    const {
      values: { week },
      setFieldValue,
    } = formikRef.current;
    const totalWeek = getState().nutritions.totalWeek;
    setFieldValue(`week.${totalWeek}`, [...week[active]]);
    dispatch(setActiveWeekNutrition(totalWeek));
    dispatch({ type: SET_TOTAL_WEEK_NUTRITION });
  };
};

export const pasteSessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { setFieldValue },
    } = formik;
    let copiedSession = getState().nutritions.copiedSession;
    let index = getState().nutritions.activeDay;
    let activeWeek = getState().nutritions.activeWeek;
    const whereTo = `week.${activeWeek}.[${index}]`;

    setFieldValue(whereTo, copiedSession);
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: null,
    });
  };
};

export const SET_CURRENT_COPIED_ITEM = "set_current_copied_item";
export const copySessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { values },
    } = formik;
    let index = getState().nutritions.activeDay;
    let activeWeek = getState().nutritions.activeWeek;
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: values.week[activeWeek][index],
    });
  };
};

export const addNutrition = (response = {}, indexLists, setFieldValue) => {
  return (dispatch, getState) => {
    const { week, groupIndex, exercisesList } = indexLists;
    let activeDay = getState().nutritions.activeDay;
    const whereTo = `week.${week}.[${activeDay}].meals[${groupIndex}].recipes`;
    setFieldValue(whereTo, [...exercisesList, response["_id"]]);
  };
};

function startKeyFromOne(obj) {
  const newObj = {};
  let index = 1;

  for (const key in obj) {
    newObj[index.toString()] = obj[key];
    index++;
  }

  return newObj;
}

export const returnWeeksLists = (values = {}, nutritionId = null) => {
  return (dispatch, getState) => {
    let data = {};
    let nutritionData = getState().nutritions.nutritionData;
    const weeks = startKeyFromOne(values.week);
    for (let w in weeks) {
      for (let i = 1; i <= weeks[w].length; i++) {
        const activeDay = weeks[w][i - 1];
        const meals = activeDay.meals;
        const hasNonEmptyRecipe = _.some(
          meals,
          (obj) => obj.recipes.length > 0
        );

        if (hasNonEmptyRecipe) {
          data[w] = {
            ...data[w],
            [i]: { menu: activeDay },
          };
        }
      }
    }
    nutritionData.weeks = data;
    nutritionData.objectives = _.map(nutritionData.objectives, "name");
    nutritionData.thumbnail = nutritionData.thumbnail
      ? nutritionData.thumbnail
      : THUMBNAIL_PLACEHOLDER_GRAY;
    dispatch(createNewNutrition(nutritionData, nutritionId));
  };
};

export const createNewNutrition = (values = {}, nutritionId) => {
  return (dispatch, getState) => {
    axios({
      url: `/api/coach/nutrition_programs${
        nutritionId ? `/${nutritionId}` : ""
      }`,
      method: nutritionId ? "PATCH" : "POST",
      data: values,
    })
      .then(() => {
        toast.success(
          `Nutrition ${nutritionId ? "Updated" : "Created"} Successfully.`
        );
        history.push("/nutrition/programs");
        dispatch(getNutritions());
      })
      .catch(() => {
        toast.error("Error while Creating New Nutrition");
      });
  };
};

export const getSessionsLists = (response, formikRef) => {
  return () => {
    const { setFieldValue } = formikRef.current;
    for (let weekItem in response) {
      const weekData = response[weekItem];
      for (let day in weekData) {
        const dayData = weekData[day];
        for (let session in dayData) {
          const week = Number(weekItem) - 1;
          const daydate = Number(day) - 1;
          const whereTo = `week.${week}.[${daydate}]`;
          const currentMenu = response[weekItem][day].menu;
          setFieldValue(whereTo + ".thumbnail", currentMenu.thumbnail);
          setFieldValue(whereTo + ".desciption", currentMenu.desciption);
          setFieldValue(whereTo + ".meals", currentMenu.meals);
        }
      }
    }
  };
};

function countObjectProperties(obj) {
  return Object.keys(obj).length;
}

export const CLEAN_WHOLE_NUTRITION = "clean_whole_nutrition";
export const cleanAllNutritionData = () => {
  return {
    type: CLEAN_WHOLE_NUTRITION,
  };
};

export const getCurrentNutrition = (id = null, formikRef) => {
  return (dispatch, getState) => {
    dispatch(loadingNutritions(true));
    axios
      .get(`/api/coach/nutrition_programs/${id}`)
      .then((res) => {
        dispatch(loadingNutritions(false));
        const response = res.data.data;
        dispatch(getInitialNutrition(countObjectProperties(response.weeks)));
        dispatch(setCurrentNutrition(response));

        if (formikRef) {
          dispatch(getSessionsLists(response.weeks, formikRef));
          dispatch({
            type: SET_TOTAL_WEEK_NUTRITION,
            payload: countObjectProperties(response.weeks),
          });
        } else {
          const objectives = _.map(response.objectives, (n) => {
            if (n) {
              return objectFit.find(
                (e) => e.name.toLowerCase() === n.toLowerCase()
              );
            } else {
              return [];
            }
          });

          let data = response;
          data.name = response.name;
          data.description = response.description;
          data.thumbnail = response.thumbnail;
          data.e_coach_program = response.e_coach_program;
          data.bmr_range = response.bmr_range;
          data.objectives = objectives;

          dispatch(
            setNutritionData({
              ...getState().nutritions.nutritionData,
              ...data,
            })
          );
        }
      })
      .catch((err) => {
        toast.error("having issue with fetching Nutrition Data");
        dispatch(loadingNutritions(false));
      });
  };
};
