import { Box, Drawer, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import NavSection from "layout/NavItems";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Scrollbar from "utils/Scrollbar";
import Cookie from "utils/cookie";
import useResponsive from "utils/hooks/useResponsive";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 272;

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

const PROFILE_HEIGHT = "88px";
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const naviagate = useNavigate();
    const { pathname } = useLocation();
    const isDesktop = useResponsive("up", "lg");

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <>
            <Scrollbar
                sx={{
                    height: `calc(100vh - ${PROFILE_HEIGHT})`,
                    "& .simplebar-content": {
                        display: "flex",
                        flexDirection: "column",
                    },
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                    <Link component="h1" to="/" variant="h5">
                        <img
                            src="/assets/logo/logoWhite.svg"
                            alt="logo"
                            height="40px"
                        />
                    </Link>
                </Box>
                <NavSection />
            </Scrollbar>
            <Box>
                <Stack alignItems="flex-start" sx={{ position: "relative" }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            padding: "30px 0 40px 0!important",
                            margin: "auto",
                        }}
                    >
                        <Box>
                            <Typography
                                textAlign="center"
                                sx={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    Cookie.delete("jwt");
                                    naviagate("/login");
                                }}
                            >
                                SE DECONNECTER
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </>
    );

    return (
        <RootStyle>
            {isDesktop ? (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            background: "#111111",
                            borderRight: "1px solid rgba(138, 143, 157, 0.5)",
                            height: "auto",
                            backgroundImage: "url(./assets/images/sidebar.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center bottom",
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            background: "#111111",
                            backgroundImage: "url(./assets/images/sidebar.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center bottom",
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
