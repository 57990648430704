import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemAvatar,
  Stack,
  styled,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Field from "components/Field";
import { connect } from "formik";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { toggleExerciseModel } from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import SubTitleCard from "../SubTitleCard";

const StyledListItem = styled(ListItemButton)(() => ({
  padding: 0,
  gap: "10px",
  overflow: "hidden",
  cursor: "pointer",
}));

const StyledCollapse = styled(Collapse)(() => ({
  width: "100%",
  gap: "10px",
  display: "grid",
  borderTop: "1px solid #E7E7E7",
  ".MuiCollapse-wrapperInner": {
    gap: "10px",
    display: "grid",
  },
  ".MuiCollapse-wrapper": {
    padding: "15px",
  },
}));

const ExerciseCard = ({ index: exerciseIndex, formik, exercisesList }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = formik;
  const [open, setOpen] = React.useState([]);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);

  const activeElement = values["week"][activeWeek][activeDay];

  const handleClick = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  return (
    <Droppable
      droppableId={`exercisegroup_${exerciseIndex}`}
      type="droppableSubItem"
    >
      {(provided) => (
        <Stack
          ref={provided.innerRef}
          {...provided.droppableProps}
          mb={1}
          mt={3}
          sx={{
            overflow: "hidden",
          }}
        >
          <List component="div" disablePadding>
            {(exercisesList || []).map((exercise, index) => {
              const exercises = activeElement.exercise_groups[exerciseIndex]
                ? activeElement.exercise_groups[exerciseIndex].exercises
                : [];
              return (
                <Draggable
                  draggableId={
                    whereTo +
                    `.exercise_groups[${exerciseIndex}].exercises[${index}]`
                  }
                  index={index}
                >
                  {(provided) => (
                    <Box
                      key={index}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      sx={{
                        position: "relative",
                        marginBottom: "1px",
                        border: "2px solid #E7E7E7",
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <Field
                        type="text"
                        name={
                          whereTo +
                          `.exercise_groups[${exerciseIndex}].exercises[${index}].exercise_id`
                        }
                        size="small"
                        sx={{ display: "none" }}
                      />
                      {index !== 0 && index !== exercisesList.length && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            background: "#F1F1F1",
                            position: "absolute",
                            zIndex: 1,
                            left: "50%",
                            transform: "translate(-50% , 0)",
                            top: "-10px",
                          }}
                        >
                          <img src="/assets/logo/link.svg" alt="link" />
                        </Box>
                      )}
                      <StyledListItem
                        secondaryAction={
                          <Stack direction="row" alignItems="center" gap="10px">
                            <div>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (open.includes(exercise._id + index)) {
                                    handleClick(exercise._id);
                                  }
                                  const data = [...exercises];
                                  data.splice(index, 1);
                                  setFieldValue(
                                    whereTo +
                                      `.exercise_groups[${exerciseIndex}].exercises`,
                                    [...data]
                                  );
                                }}
                              >
                                <DeleteOutlineIcon fontSize="small" />
                              </IconButton>
                              <IconButton>
                                {open.includes(exercise._id + index) ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            </div>
                          </Stack>
                        }
                        onClick={() => handleClick(exercise._id + index)}
                      >
                        <ListItemAvatar
                          sx={{ display: "flex" }}
                          onClick={() =>
                            dispatch(toggleExerciseModel(exercise))
                          }
                        >
                          <img
                            src={
                              exercise.thumbnail
                                ? exercise.thumbnail
                                : THUMBNAIL_PLACEHOLDER
                            }
                            width="75px"
                            height="56"
                            alt="gym.jpg"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                            onError={(event) => {
                              event.target.src = THUMBNAIL_PLACEHOLDER;
                              event.onerror = null;
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={exercise.name} />
                      </StyledListItem>
                      <StyledCollapse
                        in={open.includes(exercise._id + index)}
                        timeout="auto"
                        sx={{
                          width: "100%",
                          gap: "10px",
                          display: "grid",
                        }}
                      >
                        <SubTitleCard
                          exerciseIndex={exerciseIndex}
                          index={index}
                          exercise={exercise}
                        />
                      </StyledCollapse>
                    </Box>
                  )}
                </Draggable>
              );
            })}
          </List>
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  );
};

export default connect(ExerciseCard);
