import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Container, IconButton, Typography } from "@mui/material";
import SessionForm from "components/CreateProgramPage/SessionForm";
import { useState } from "react";
const CreateSession = () => {
  const [open, setOpen] = useState([0]);
  const toggle = (day) => {
    if (open.includes(day)) {
      setOpen([]);
    } else {
      setOpen([0]);
    }
  };
  return (
    <Container maxWidth="xxl">
      <Typography
        component="h2"
        fontSize="16px"
        fontWeight="600"
        fontFamily="Poppins"
        display="flex"
        alignItems="center"
        gap="10px"
        pr={2}
      >
        <IconButton onClick={() => toggle(0)}>
          {(open || []).includes(0) ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )}
        </IconButton>
        Détail de la séance
      </Typography>
      <SessionForm open={open} isIndependentSession={true} />
    </Container>
  );
};

export default CreateSession;
