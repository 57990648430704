import axios from "axios";
import history from "components/history";
import { toast } from "react-hot-toast";

export const LOADING_EXERCISE_DATA = "loading_exercise_data";
export const loadingExercise = (response = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_EXERCISE_DATA,
      payload: response,
    });
  };
};

export const GET_EXERCISE_DATA = "get_exercise_data";
export const getExercises = () => {
  return (dispatch, getState) => {
    dispatch(loadingExercise(true));
    axios
      .get("/api/coach/exercises")
      .then((res) => {
        dispatch(loadingExercise(false));
        dispatch({
          type: GET_EXERCISE_DATA,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        toast.error("having issue with fetching Exercise Data");
        dispatch(loadingExercise(false));
      });
  };
};

export const createExercises = (values = {}, id = null) => {
  return () => {
    return axios({
      method: id ? "PATCH" : "POST",
      url: id ? `/api/coach/exercises/${id}` : "/api/coach/exercises",
      data: values,
    })
      .then((res) => {
        if (id) {
          toast.success("Exercise Updated successfully!");
        } else {
          toast.success("New Exercise Added successfully!");
        }
        history.push("/fitness/exercices");
      })
      .catch((err) => {
        toast.error("having issue with create exercise");
      });
  };
};
export const SEARCH_EXERCISE_DATA = "search_exercise_data";
export const searchExercise = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().exercise.searchedExercise || []).filter(
      (e) => e?.name?.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_EXERCISE_DATA,
      payload: filteredItem,
    });
  };
};

export const PAGINATION_EXERCISE_DATA = "pagination_exercise_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATION_EXERCISE_DATA,
      payload: response,
    });
  };
};

export const GET_EXERCISE_CURRENT = "get_exercise_current";
export const cleanupExercise = () => {
  return {
    type: GET_EXERCISE_CURRENT,
  };
};
export const getCurrentExercises = (id = null) => {
  return (dispatch) => {
    dispatch(loadingExercise(true));
    return axios
      .get(`/api/coach/exercises/${id}`)
      .then((res) => {
        dispatch(loadingExercise(false));
        dispatch({
          type: GET_EXERCISE_CURRENT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(loadingExercise(false));
      });
  };
};
