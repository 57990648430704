import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanAllProgramData, getInitialProgram } from "store/actions/program";
import {
  changePagination,
  deleteSessionById,
  getSessions,
  searchSessions,
} from "store/actions/sessions";
import SessionListBody from "./SessionListBody";
import SessionListHeader from "./SessionListHeader";

const SessionList = () => {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state.sessions.sessions);
  const navigate = useNavigate();
  const isLoadingSessions = useSelector(
    (state) => state.sessions.isLoadingSessions
  );
  const currentPage = useSelector((state) => state.sessions.currentPage);
  const [current, setCurrent] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (sessions || []).slice(start, end).length;

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };
  useEffect(() => {
    dispatch(getSessions());
  }, []);
  return (
    <Container maxWidth="xxl">
      <Stack direction="row" alignItems="center">
        <Typography component="h2" fontSize="18px" fontWeight="600" pr={2}>
          Sessions
        </Typography>
        <Typography
          component="h2"
          fontSize="18px"
          fontWeight="600"
          color="#999999"
          pl={2}
          borderLeft="2px solid #D9D9D9"
          lineHeight="10px"
        >
          {(sessions || []).length}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        mt={2}
      >
        <TextField
          placeholder="Rechercher sessions"
          name="search sessions"
          sx={{
            width: { sm: "100%", md: "414px" },
            ".MuiInputBase-root": {
              background: "#F1F1F1",
              borderRadius: "40px",
            },
            ".MuiInputBase-input": {
              height: "10px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => dispatch(searchSessions(e.target.value))}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(cleanAllProgramData());
            dispatch(getInitialProgram());
            navigate("/fitness/sessions/create");
          }}
        >
          {" "}
          + créer un Session
        </Button>
      </Stack>
      <Stack gap={1} mt={2}>
        <Loader isLoading={isLoadingSessions} />
        {!isLoadingSessions && <SessionListHeader />}
        {(sessions || []).length === 0 && !isLoadingSessions && (
          <h5>No Data Available</h5>
        )}

        {!isLoadingSessions &&
          (sessions || [])
            .slice(start, end)
            .map((session, index) => (
              <SessionListBody
                key={index}
                session={session}
                toggleDeleteModel={toggleDeleteModel}
              />
            ))}
        {!isLoadingSessions && (sessions || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start}-{start + currentPageItems} sur {sessions.length}{" "}
              results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(sessions.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer la session"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.name}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        handleClose={toggleDeleteModel}
        maxWidth="sm"
        onConfirm={() => {
          dispatch(deleteSessionById(current._id, toggleDeleteModel));
        }}
      />
    </Container>
  );
};

export default SessionList;
