import { Box, Container, Stack } from "@mui/material";
import IngrediantsContent from "components/EditNutritionPage/IngrediantsContent";
import NutritionIntakes from "components/EditNutritionPage/NutritionIntakes";
import RecipeDescription from "components/EditNutritionPage/RecipeDescription";
import RecipePreview from "components/EditNutritionPage/RecipePreview";
import { FormContext } from "components/FormContext";
import Loader from "components/Loader";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createNewRecipe,
  getCurrentRecipe,
  updateRecipe,
} from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import usePrompt from "utils/hooks/usePrompt";
import { recipes } from "utils/recipe.utils";

const EditNutrition = () => {
  const { formikRef } = useContext(FormContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePrompt(isFormDirty);

  const isLoadingRecipes = useSelector(
    (state) => state.recipes.isLoadingRecipes
  );

  useEffect(() => {
    if (id) {
      dispatch(getCurrentRecipe(id, formikRef.current));
    }
  }, []);
  return (
    <Container maxWidth="xxl">
      <Loader isLoading={isLoadingRecipes} />
      <div style={{ display: isLoadingRecipes ? "none" : "" }}>
        <Formik
          innerRef={formikRef}
          initialValues={{ ...recipes.initialValues }}
          onSubmit={(values) => {
            setIsFormDirty(false);
            const data = _.pick(values, [
              "name",
              "prep_time",
              "cook_time",
              "kcal",
              "thumbnail",
              "video",
              "difficulty_level",
              "explore",
              "steps",
              "recipe_ingredients",
              "free",
              "in_explorer",
              "high_calories",
              "serving",
              "meal_type",
            ]);
            data.explore = _.map(data.explore, "name");
            data.recipe_nutritions = [
              { name: "protin", value: values.protin },
              { name: "lipides", value: values.lipides },
              { name: "glucides", value: values.glucides },
            ];
            data.recipe_ingredients = _.map(
              data.recipe_ingredients,
              (value, index) => {
                return {
                  name: `Ingredient ${index + 1}`,
                  description: value,
                };
              }
            );
            data.thumbnail = data.thumbnail
              ? data.thumbnail
              : THUMBNAIL_PLACEHOLDER_GRAY;
            if (id) {
              dispatch(updateRecipe(id, data));
            } else {
              dispatch(createNewRecipe(data));
            }
          }}
        >
          {(props) => {
            const { handleSubmit, isSubmitting, dirty } = props;
            if (!isSubmitting) setIsFormDirty(dirty);
            return (
              <form onSubmit={handleSubmit}>
                <Box gap={4} display="grid">
                  <Box gap={4} display="grid">
                    <Stack>
                      <TitleHeader number={1} title="Aperçu de la recette" />
                      <RecipePreview />
                    </Stack>
                    <Stack>
                      <TitleHeader number={2} title="Apports nutritionnels" />
                      <NutritionIntakes />
                    </Stack>
                    <Stack>
                      <TitleHeader number={3} title="Ingredients" />
                      <IngrediantsContent />
                    </Stack>
                    <Stack>
                      <TitleHeader
                        number={4}
                        title="Étapes & descriptions de la recette"
                      />
                      <RecipeDescription />
                    </Stack>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default EditNutrition;
