import { Box } from "@mui/material";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  PrimaryOption,
  explorerCategory,
  objectFit,
  secondaryOption,
  sexeOptions,
} from "../../../utils/gym.utils";
import { multiSelect } from "../../Field/validate";

const ProgramParameter = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const [targetedMusclesForMen, setTargetedMusclesForMen] =
    useState(PrimaryOption);
  const [targetedMusclesForWoman, setTargetedMusclesForWoman] =
    useState(secondaryOption);
  useEffect(() => {
    if (values.in_explorer) {
      setFieldValue("free_program", false);
      setFieldValue("e_coach_program", false);
    } else {
      setFieldValue("free_program", false);
    }
  }, [values.in_explorer]);
  useEffect(() => {
    if (values.free_program) {
      setFieldValue("in_explorer", true);
      setTimeout(() => {
        setFieldValue("free_program", true);
      });
    }
  }, [values.free_program]);

  useEffect(() => {
    if (values.e_coach_program) {
      setFieldValue("in_explorer", false);
      setTimeout(() => {
        setFieldValue("e_coach_program", true);
      });
    }
  }, [values.e_coach_program]);

  useEffect(() => {
    if (values.sexe.some((e) => e.name === "Femme")) {
      targetedMusclesChangeHandler(setTargetedMusclesForWoman, secondaryOption);
    } else {
      targetedMusclesChangeHandler(setTargetedMusclesForMen, PrimaryOption);
    }
  }, [values.zone_musculaire, values.sexe]);

  const targetedMusclesChangeHandler = (setTargetedMuscles, defaultOptions) => {
    const hasMainCategorySelected = values.zone_musculaire.some(
      (item) => item.id === 7 || item.id === 8 || item.id === 9
    );
    if (hasMainCategorySelected && values.zone_musculaire?.length > 0) {
      setTargetedMuscles(setOptionsDisabled(defaultOptions));
    } else if (values.zone_musculaire?.length > 0) {
      setTargetedMuscles(setMainOptionsDisabled(defaultOptions));
    } else {
      setTargetedMuscles(defaultOptions);
    }
  };

  const setOptionsDisabled = (arr) => {
    return _.map(arr, (item) => {
      if (item.id !== 7 && item.id !== 8 && item.id !== 9) {
        item = {
          ...item,
          disabled: true,
        };
      }
      return item;
    });
  };

  const setMainOptionsDisabled = (arr) => {
    return _.map(arr, (item) => {
      if (item.id === 7 || item.id === 8 || item.id === 9) {
        item = {
          ...item,
          disabled: true,
        };
      }
      return item;
    });
  };

  return (
    <>
      <Field
        type="switch"
        label="Ajouter le programme à l’Explorer"
        name="in_explorer"
        customLable={true}
      />
      {values.in_explorer && (
        <Box variant="outlined" sx={{ mb: 3 }}>
          <Field
            type="multiselect"
            notched={true}
            placeholder="Selectionner une ou plusieurs catégories"
            name="categories"
            size="small"
            options={explorerCategory}
          />
        </Box>
      )}
      <Field
        type="switch"
        label="Disponible dans Evo Basic"
        name="free_program"
        customLable={true}
      />
      <Field
        type="switch"
        label="Ajouter le programme à l’E-Coach"
        name="e_coach_program"
        customLable={true}
      />
      {values.e_coach_program && (
        <>
          <Field
            type="multiselect"
            label="SEXE"
            placeholder="Sexe"
            name="sexe"
            notched={true}
            options={sexeOptions}
            validate={multiSelect}
          />
          <Field
            type="multiselect"
            label="OBJECTIF"
            placeholder="Objectif"
            name="available_primaire"
            notched={true}
            options={objectFit}
            validate={multiSelect}
          />
          <Field
            type="multiselect"
            label="Targeted Muscles"
            placeholder="Targeted Muscles"
            name="zone_musculaire"
            notched={true}
            options={
              values.sexe.some((e) => e.name === "Femme")
                ? targetedMusclesForWoman
                : targetedMusclesForMen
            }
            validate={multiSelect}
          />
        </>
      )}
    </>
  );
};

export default connect(ProgramParameter);
