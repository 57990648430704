import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import CustomModal from "components/CustomModal";
import { connect } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "store/actions/program";
import FileUploaderModel from "../EditProgram/FileUploaderModel";

const CardImageUploader = (props) => {
  const { setFieldValue, values } = props.formik;
  const dispatch = useDispatch();
  const [showImageUploader, setImageUploader] = useState(false);
  const [file, setFile] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  useEffect(() => {
    setFile(props.thumbnail);
  }, [props.thumbnail]);

  const toggleImageUploader = () => {
    setImageUploader(!showImageUploader);
  };

  const togglePreviewModal = () => {
    setOpenPreviewModal(!openPreviewModal);
  };

  const styles = {
    position: "absolute",
    height: "23px",
    right: "50%",
    transform: "translate(50%,-50%)",
    top: "50%",
  };

  const renderImage = () => {
    if (file) {
      if (typeof file === "string") {
        return file;
      } else {
        return URL.createObjectURL(file);
      }
    } else {
      return "/assets/images/Photothumbnail.jpg";
    }
  };

  const renderVideo = () => {
    if (values.video) {
      return values.video;
    } else {
      return "/assets/images/Photothumbnail.jpg";
    }
  };
  return (
    <Card
      sx={{
        flex: 1,
        display: "flex",
        boxShadow: "none",
        borderRadius: "10px",
        border: "2px solid #E7EBF0",
        cursor: "pointer",
        mb: 3,
      }}
    >
      {props.isVideo ? (
        <div style={{ position: "relative", background: "#F1F1F1" }}>
          <img src="/assets/images/video.png" alt="" style={styles} />
          <CardMedia
            component="video"
            sx={{
              maxWidth: 151,
              maxHeight: "65px",
              objectFit: "contain",
              ml: "-1px",
            }}
            image={renderVideo()}
            alt="Live from space album cover"
            onClick={() =>
              values.video ? togglePreviewModal() : toggleImageUploader()
            }
          />
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          {!file && (
            <img
              src="/assets/images/camera.png"
              alt=""
              style={{ ...styles, height: "20px" }}
            />
          )}
          <CardMedia
            component="img"
            sx={{
              maxWidth: 151,
              maxHeight: "65px",
              objectFit: "contain",
              ml: "-1px",
            }}
            image={renderImage()}
            alt="Live from space album cover"
            onClick={() =>
              file ? togglePreviewModal() : toggleImageUploader()
            }
          />
        </div>
      )}
      <Box
        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
        onClick={toggleImageUploader}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            alignItems: "center",
            "&.MuiCardContent-root": {
              display: "flex",
              alignItems: "center",
              padding: "10px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="#7742E9"
            fontWeight="600"
            fontSize="14px"
            textTransform="capitalize"
          >
            {props.label ? props.label : "CHANGER L’APERÇU"}
          </Typography>
        </CardContent>
      </Box>
      <FileUploaderModel
        isOpen={showImageUploader}
        toggle={toggleImageUploader}
        isVideo={props.isVideo}
        name={props.name}
        callback={(file) => {
          return dispatch(uploadFile(file, props.apiKey)).then((response) => {
            setFieldValue(props.name, response);
            setFile(file);
            toggleImageUploader();
          });
        }}
      />
      <CustomModal open={openPreviewModal} onCloseHandler={togglePreviewModal}>
        {props.isVideo ? (
          <CardMedia
            component="video"
            image={renderVideo()}
            alt="Live from space album cover"
            controls
          />
        ) : (
          <CardMedia
            component="img"
            image={renderImage()}
            alt="Live from space album cover"
          />
        )}
      </CustomModal>
    </Card>
  );
};

export default connect(CardImageUploader);
