import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePagination,
  deleteRecipe,
  getRecipeLists,
  searchRecipes,
} from "store/actions/recipes";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import ExecericeTitles from "./ExecericeTitles";
import ExcerciceItem from "./ExecrciceItem";

const NutritionPage = () => {
  useDocumentTitle("Mes Recettes", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recipes = useSelector((state) => state.recipes.recipesLists);
  const isLoadingRecipes = useSelector(
    (state) => state.recipes.isLoadingRecipes
  );
  const currentPage = useSelector((state) => state.recipes.currentPage);
  const [current, setCurrent] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [showInfoModel, setInfoModel] = useState(false);

  const toggleDeleteModel = (element = null) => {
    setCurrent(element);
    setDeleteModel(!showDeleteModel);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };

  useEffect(() => {
    dispatch(getRecipeLists());
  }, []);

  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (recipes || []).slice(start, end).length;
  return (
    <Container maxWidth="xxl">
      <Stack gap={3}>
        <Stack direction="row" alignItems="center">
          <Typography component="h2" fontSize="18px" fontWeight="600" pr={2}>
            Recipes
          </Typography>
          <Typography
            component="h2"
            fontSize="18px"
            fontWeight="600"
            color="#999999"
            pl={2}
            borderLeft="2px solid #D9D9D9"
            lineHeight="10px"
          >
            {(recipes || []).length}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TextField
            placeholder="Rechercher un recipe"
            name="searchRecipes"
            sx={{
              width: { sm: "100%", md: "414px" },
              ".MuiInputBase-root": {
                background: "#F1F1F1",
                borderRadius: "40px",
              },
              ".MuiInputBase-input": {
                height: "10px",
              },
            }}
            onChange={(e) => dispatch(searchRecipes(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/nutrition/recipes/create")}
          >
            {" "}
            + créer un recette
          </Button>
        </Stack>
        <Stack gap={1}>
          <Loader isLoading={isLoadingRecipes} />
          {!isLoadingRecipes && (
            <Box gap={1} sx={{ minHeight: "700px" }}>
              <ExecericeTitles />
              {(recipes || []).slice(start, end).map((recipe) => (
                <ExcerciceItem
                  key={recipe["_id"]}
                  recipe={recipe}
                  toggleDeleteModel={toggleDeleteModel}
                  toggleInfoModel={toggleInfoModel}
                />
              ))}
            </Box>
          )}
        </Stack>
        {(recipes || []).length === 0 && !isLoadingRecipes && (
          <h5>No Data Available</h5>
        )}
        {(recipes || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start + 1}-{start + currentPageItems} sur{" "}
              {recipes.length} results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(recipes.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer la recette"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.name}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(deleteRecipe(current._id));
          toggleDeleteModel();
        }}
      />
      <ConfirmationModel
        showActions={false}
        isOpen={showInfoModel}
        title="Supprimer l’exercice"
        body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
        handleClose={toggleInfoModel}
      />
    </Container>
  );
};

export default NutritionPage;
