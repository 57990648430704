import styled from "@emotion/styled";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SET_CURRENT_CLIENT_DETAILS,
  deactivateClientById,
  deleteClientById,
  getClientDetails,
  resetClientPassword,
} from "store/actions/clients";
import { formatDate, fullName } from "utils/common";
const StyleCard = styled(Card)(() => ({
  padding: "20px",
  borderRadius: "12px",
  ".text-right": {
    textAlign: "right",
  },
  ".text-capitalize": {
    textTransform: "capitalize",
  },
  ".grey-box": {
    backgroundColor: "#f1f1f1",
    padding: "40px 20px",
    borderRadius: "12px",
  },
  ".active": {
    color: "#2798A8",
    fontWeight: 700,
  },
  ".inactive": {
    color: "#D40000",
    fontWeight: 700,
  },
  ".disabled": {
    cursor: "default",
    opacity: "0.7",
  },
}));

const ClientDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useSelector((state) => state.clients.currentClientDetails);
  const { type } = useSelector((state) => state.user.userDetails);
  const [showDeleteModel, setDeleteModal] = useState(false);
  const [showDeactivateModal, setDectivateModal] = useState(false);
  const [showResetPassword, setResetPassword] = useState(false);

  const toggleDeleteModal = (ele = "") => {
    setDeleteModal(!showDeleteModel);
  };

  const toggleDeactivateModal = (ele = "") => {
    setDectivateModal(!showDeactivateModal);
  };

  const toggleResetPassword = () => {
    setResetPassword(!showResetPassword);
  };

  useEffect(() => {
    dispatch(getClientDetails(id));
  }, []);

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} sm={6}>
          <StyleCard variant="outlined">
            <Typography
              component="h4"
              fontWeight="700"
              mb={2}
              sx={{ fontSize: { sx: "14px", md: "24px" } }}
            >
              {fullName(client.first_name, client.last_name)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                User ID:
              </Grid>
              <Grid item xs={6} className="text-right">
                {client._id}
              </Grid>
              <Grid item xs={6}>
                Status
              </Grid>
              <Grid
                item
                xs={6}
                className={`text-right ${
                  client.status === "ACTIF" ? "active" : "inactive"
                }`}
              >
                {client.status}
              </Grid>
              <Grid item xs={6}>
                Email Address:
              </Grid>
              <Grid item xs={6} className="text-right">
                <Tooltip title={client.email} arrow>
                  <Typography
                    mr={0}
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                    }}
                  >
                    {client.email}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                Phone Number:
              </Grid>
              <Grid item xs={6} className="text-right">
                {client.phone ? client.phone : "-"}
              </Grid>
              <Grid item xs={6}>
                Member Since
              </Grid>
              <Grid item xs={6} className="text-right text-capitalize">
                {formatDate(client.created_at)}
              </Grid>
              <Grid item xs={6}>
                Account Created from:
              </Grid>
              <Grid item xs={6} className="text-right">
                {client.referrer}
              </Grid>
            </Grid>

            {(type === "ADMIN" || type === "COACH") && (
              <>
                <Divider
                  sx={{ margin: "20px 0", padding: "0px" }}
                  variant="middle"
                />
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    {type === "ADMIN" && (
                      <Link
                        component="button"
                        onClick={toggleResetPassword}
                        sx={{
                          fontSize: "12px",
                          color: "#222222",
                          fontWeight: "500",
                        }}
                      >
                        Réinitialiser mot de passe
                      </Link>
                    )}
                  </Grid>

                  <Grid item xs={5} className="text-right">
                    {(type === "ADMIN" || type === "COACH") && (
                      <Link
                        disabled={client.status === "INACTIF"}
                        component="button"
                        onClick={() => toggleDeactivateModal()}
                        className={
                          client.status === "INACTIF" ? "disabled" : ""
                        }
                        sx={{
                          fontSize: "12px",
                          color: "#222222",
                          fontWeight: "500",
                        }}
                      >
                        Désactiver
                      </Link>
                    )}
                    {type === "ADMIN" && (
                      <Link
                        component="button"
                        onClick={toggleDeleteModal}
                        sx={{
                          fontSize: "12px",
                          color: "#D40000",
                          fontWeight: "500",
                          textDecorationColor: "#D40000",
                          marginLeft: "30px",
                        }}
                      >
                        Supprimer
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </StyleCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          {client.subscription && (
            <StyleCard variant="outlined">
              <Typography
                component="h5"
                fontWeight="600"
                mb={2}
                sx={{ fontSize: { sx: "12px", md: "16px" } }}
              >
                Client Subscription Plan
              </Typography>
              <Box className="grey-box" mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      component="div"
                      fontWeight="500"
                      textTransform="uppercase"
                      sx={{ fontSize: { sx: "16px", md: "24px" } }}
                    >
                      Evo Dream {client.subscription?.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      component="div"
                      fontWeight="700"
                      textAlign="right"
                      sx={{ fontSize: { sx: "16px", md: "24px" } }}
                    >
                      {client.subscription?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {client.subscription?.start_date &&
                client.subscription?.end_date && (
                  <Box className="grey-box">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography component="div">Plan started:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          component="div"
                          fontWeight="700"
                          textAlign="right"
                          className="text-capitalize"
                        >
                          {client.subscription?.start_date
                            ? formatDate(client.subscription?.start_date)
                            : "-"}
                        </Typography>
                      </Grid>
                      {client.subscription?.end_date &&
                        client.subscription?.type === "FREE" && (
                          <>
                            <Grid item xs={6}>
                              <Typography component="div">
                                Plan ended:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                component="div"
                                fontWeight="700"
                                textAlign="right"
                                className="text-capitalize"
                              >
                                {formatDate(client.subscription?.end_date)}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      {client.subscription?.type !== "FREE" && (
                        <>
                          <Grid item xs={8}>
                            <Typography component="div" fontSize="12px" pt={1}>
                              Renoouvellement automatique le{" "}
                              {formatDate(client.subscription?.end_date)}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                )}
            </StyleCard>
          )}
        </Grid>
      </Grid>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Alerte de suppression de compte"
        body={[
          "Vous êtes sur le point de supprimer le compte d'un utilisateur. Cette action n'est ",
          <strong>PAS RÉVERSIBLE. </strong>,
          "Êtes-vous sur de vouloir continuer?",
        ]}
        handleClose={toggleDeleteModal}
        maxWidth="sm"
        onConfirm={() => {
          dispatch(deleteClientById(client._id, toggleDeleteModal)).then(() =>
            navigate("/clients")
          );
        }}
      />
      <ConfirmationModel
        isOpen={showDeactivateModal}
        title="Alerte de désactivation de compte"
        body="Vous êtes sur le point de désactiver le compte d'un utilisateur. Êtes-vous sur de vouloir continuer?"
        handleClose={toggleDeactivateModal}
        maxWidth="sm"
        confirm={"désactiver"}
        onConfirm={() => {
          dispatch(
            deactivateClientById(client._id, toggleDeactivateModal)
          ).then(() => {
            dispatch({
              type: SET_CURRENT_CLIENT_DETAILS,
              payload: { ...client, status: "INACTIF" },
            });
          });
        }}
      />
      <ConfirmationModel
        isOpen={showResetPassword}
        title="Réinitialiser mot de passe"
        body="Êtes-vous sûr de vouloir réinitialiser le mot de passe du client ?"
        handleClose={() => toggleResetPassword()}
        confirm={"réinitialiser"}
        maxWidth="sm"
        onConfirm={() => {
          dispatch(resetClientPassword(client.email, toggleResetPassword));
        }}
      />
    </Container>
  );
};

export default ClientDetails;
