import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import Field from "components/Field";
import { email, required } from "components/Field/validate";
import { Formik } from "formik";
import { sha512 } from "js-sha512";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserDetails } from "store/actions/user";
import Cookie from "../../../utils/cookie";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "50px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        <Stack>
          <Typography
            component="span"
            sx={{
              color: "primary.main",
              fontWeight: 400,
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            {" "}
            <img src="/assets/logo/Logo.png" alt="google" height="47" />
          </Typography>
        </Stack>
        <Stack>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="700"
            fontSize="24px"
            textAlign="center"
          >
            {" "}
            Welcome back, Coach!
          </Typography>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="400"
            fontSize="16px"
            textAlign="center"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                ...values,
                deviceId: "xxxx",
                deviceType: "ios",
              };
              data.password = sha512(values.password);
              setSubmitting(true);
              axios
                .post(`/api/auth/coach/signing`, data)
                .then((res) => {
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${res?.data?.data?.accessToken}`;
                  Cookie.save("jwt", res?.data?.data?.accessToken);
                  const userDetails = jwt_decode(res?.data?.data?.accessToken);
                  dispatch(setUserDetails(userDetails));
                  toast.success("Login Successfully.");
                  navigate("/");
                  setSubmitting(false);
                })
                .catch((err) => {
                  setSubmitting(false);
                  toast.error(
                    err?.response?.data?.message || "Invalid Access."
                  );
                });
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    label="Email"
                    placeholder="Enter name here..."
                    name="email"
                    validate={email}
                  />
                  <Field
                    type="password"
                    label="Password"
                    placeholder="Enter Password here..."
                    name="password"
                    validate={required}
                  />
                  <Stack width="100%" mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {" "}
                      Sign In
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
          <Typography
            component="span"
            textAlign="center"
            sx={{
              fontWeight: 400,
              textDecoration: "underline",
              fontSize: "16px",
              cursor: "pointer",
              color: "#C736EB",
            }}
            onClick={() => navigate("/forget-password")}
          >
            {" "}
            Forgot password?
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default LoginForm;
