import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import AddExerciseModel from "../AddExerciseModel";
import AddVariableModel from "../AddVariableModel";
import ExerciseCard from "./ExerciseCard";

const StyledCollapse = styled(Collapse)(() => ({
  "&.MuiCollapse-hidden .MuiList-root": {
    display: "none",
  },
}));

const SeanceExercise = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [addExercides, setaddExercidesModel] = useState(false);
  const [open, setOpen] = React.useState([]);
  const [current, setCurrent] = React.useState(null);
  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  const addGroup = () => {
    let exercisesList = activeElement ? activeElement["exercise_groups"] : [];
    const newElement = {
      name: "",
      exercises: [],
    };
    setOpen((prev) => [...prev, (exercisesList || []).length]);
    setFieldValue(whereTo + `.exercise_groups`, [...exercisesList, newElement]);
    setFieldValue(whereTo + `.muscle${exercisesList?.length}`, 1);
  };

  const delteGroup = (index = null) => {
    let exercisesList = activeElement ? activeElement["exercise_groups"] : [];

    const data = [...exercisesList];
    data.splice(index, 1);
    setFieldValue(whereTo + `.exercise_groups`, [...data]);
    setFieldValue(whereTo + `.muscle${index}`, 1);
  };

  const toggleCollapse = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const toggleExerciseModel = (response = null) => {
    setCurrent(response);
    setaddExercidesModel(!addExercides);
  };

  const renderExercisesLists = useMemo(() => {
    let list = [];
    const exercise_groups = activeElement
      ? (activeElement.exercise_groups || []).length
      : 0;
    for (let index = 0; index < exercise_groups; index++) {
      const exercises = activeElement.exercise_groups[index]
        ? activeElement.exercise_groups[index].exercises
        : [];
      const totalSets = exercises
        ? exercises[0]
          ? (exercises[0].sets || []).length
          : 1
        : 1;
      const exercisesList = exercises ? _.map(exercises, "program") : [];
      list.push(
        <Draggable draggableId={`exercise_${index}`} index={index}>
          {(provided) => (
            <Grid
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              key={index}
              container
              my={3}
              columnSpacing={1}
              alignItems="center"
              sx={{
                background: "#F1F1F1",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              {!_.isEmpty(exercisesList) && (
                <Grid
                  item
                  xs={12}
                  md={1}
                  display="flex"
                  sx={{
                    "&.MuiGrid-item": {
                      paddingLeft: "0px",
                    },
                  }}
                >
                  <AvatarGroup max={3} spacing="small">
                    {exercisesList.map((img, index) => (
                      <Avatar alt="image" key={index} src={img?.thumbnail} />
                    ))}
                  </AvatarGroup>
                </Grid>
              )}
              <Grid item xs={12} md={_.isEmpty(exercisesList) ? 1 : 1.5}>
                <Field
                  mb={0}
                  type="select"
                  label="sets"
                  name={whereTo + `.muscle${index}`}
                  size="small"
                  placeholder="sets"
                  disabled={_.isEmpty(exercisesList)}
                  defaultValue={totalSets}
                  selectsx={{ background: "white", borderRadius: "10px" }}
                >
                  <MenuItem value={1}>1 set</MenuItem>
                  <MenuItem value={2}>2 sets</MenuItem>
                  <MenuItem value={3}>3 sets</MenuItem>
                  <MenuItem value={4}>4 sets</MenuItem>
                  <MenuItem value={5}>5 sets</MenuItem>
                  <MenuItem value={6}>6 sets</MenuItem>
                </Field>
              </Grid>
              <Grid
                item
                md={_.isEmpty(exercisesList) ? 10 : 8.5}
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                <Field
                  type="text"
                  name={whereTo + `.exercise_groups[${index}].name`}
                  size="small"
                  placeholder="Enter Exercise group Name"
                  mb={0}
                  selectsx={{ background: "white", borderRadius: "10px" }}
                />
              </Grid>
              <Grid item md={1} alignItems="center">
                <Stack direction="row" justifyContent="end">
                  <IconButton onClick={() => delteGroup(index)}>
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => toggleCollapse(index)}>
                    {open.includes(index) ? (
                      <ExpandLess fontSize="small" />
                    ) : (
                      <ExpandMore fontSize="small" />
                    )}
                  </IconButton>
                </Stack>
              </Grid>

              <StyledCollapse
                in={open.includes(index)}
                timeout="auto"
                sx={{ width: "100%" }}
              >
                <ExerciseCard index={index} exercisesList={exercisesList} />
                <Button
                  sx={{
                    width: "100%",
                    mt: 2,
                    fontSize: "12px",
                    borderRadius: "20px",
                    textTransform: "none",
                    background: "#E7E7E7",
                  }}
                  onClick={() => toggleExerciseModel(index)}
                >
                  <span
                    style={{
                      border: "1px dashed #999999",
                      display: "block",
                      width: "100%",
                      borderRadius: "20px",
                      padding: "3px",
                    }}
                  >
                    + Add another exercise
                  </span>
                </Button>
              </StyledCollapse>
            </Grid>
          )}
        </Draggable>
      );
    }
    return list;
  }, [activeElement, open]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceExerciseGroupIndex = parseInt(source.droppableId.split("_")[1]);
    const destinationExterciseGroupIndex = parseInt(
      destination.droppableId.split("_")[1]
    );
    if (source.droppableId === destination.droppableId) {
      if (result.type === "droppableSubItem") {
        const items = reorder(
          activeElement["exercise_groups"][sourceExerciseGroupIndex].exercises,
          result.source.index,
          result.destination.index
        );
        setFieldValue(
          whereTo + `.exercise_groups[${sourceExerciseGroupIndex}].exercises`,
          [...items]
        );
      }
      if (result.type === "droppableItem") {
        keepListItemInExistingState(source, destination);
        const items = reorder(
          activeElement["exercise_groups"],
          result.source.index,
          result.destination.index
        );
        const sourceSetVal = activeElement[`muscle${source.index}`];
        const destinationSetVal = activeElement[`muscle${destination.index}`];
        const updatedActiveElement = {
          ...activeElement,
          [`muscle${destination.index}`]: sourceSetVal,
          [`muscle${source.index}`]: destinationSetVal,
          exercise_groups: [...items],
        };

        setFieldValue(whereTo, updatedActiveElement);
      }
    } else {
      const sourceExercises =
        activeElement["exercise_groups"][sourceExerciseGroupIndex].exercises;
      const destinationExercises =
        activeElement["exercise_groups"][destinationExterciseGroupIndex]
          .exercises;
      moveItemBetweenList(
        sourceExercises,
        destinationExercises,
        source,
        destination,
        sourceExerciseGroupIndex,
        destinationExterciseGroupIndex
      );
    }
  };

  const keepListItemInExistingState = (source, destination) => {
    const sourceItemIndex = _.indexOf(open, source.index);
    const destinationItemIndex = _.indexOf(open, destination.index);
    if (sourceItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, destination.index);
      setOpen(open);
    } else if (destinationItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, source.index);
      setOpen(open);
    } else {
      const updatedOpen = _.map(open, (o) => {
        if (destination.index < o) {
          o++;
        } else if (destination.index > o) {
          o--;
        }
        return o;
      });
      setOpen(updatedOpen);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const moveItemBetweenList = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    sourceExerciseGroupIndex,
    destinationExterciseGroupIndex
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    setFieldValue(
      whereTo + `.exercise_groups[${sourceExerciseGroupIndex}].exercises`,
      sourceClone
    );
    setFieldValue(
      whereTo + `.exercise_groups[${destinationExterciseGroupIndex}].exercises`,
      destClone
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box py={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            component="h2"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Poppins"
            pr={2}
          >
            Exercices de la séance
          </Typography>
        </Stack>
        <Droppable
          droppableId={`exterciseslist_${activeDay}`}
          type="droppableItem"
        >
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {renderExercisesLists}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Button
          sx={{
            width: "100%",
            padding: "14px",
            fontSize: "14px",
            fontWeight: "600",
            borderRadius: "8px",
            mt: 2,
            background:
              "linear-gradient(86.64deg, rgba(9, 51, 198, 0.12) 0%, rgba(74, 147, 255, 0.12) 100%)",
          }}
          onClick={addGroup}
        >
          + Add Exercise Group
        </Button>
        {addExercides && (
          <AddExerciseModel
            isOpen={addExercides}
            current={current}
            toggle={toggleExerciseModel}
          />
        )}
        <AddVariableModel />
      </Box>
    </DragDropContext>
  );
};

export default connect(SeanceExercise);
