import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePagination,
  cleanAllNutritionData,
  deleteNutrition,
  getInitialNutrition,
  getNutritions,
  searchNutritions,
} from "store/actions/nutritions";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import ConfirmationModel from "../../components/ConfirmationModel";
import Loader from "../../components/Loader";
import NutritionItem from "./NutritionItem";
import NutritionTitles from "./NutritionTitles";

const NutritionPlans = () => {
  useDocumentTitle("Mes Programmes Nutrition", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nutritions = useSelector((state) => state.nutritions.nutritions);
  const currentPage = useSelector((state) => state.nutritions.currentPage);
  const isLoadingNutritins = useSelector(
    (state) => state.nutritions.isLoadingNutritins
  );

  const [showDeleteModel, setDeleteModel] = useState(false);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    dispatch(getNutritions());
  }, []);

  const toggleDeleteModel = (response = null) => {
    setCurrent(response);
    setDeleteModel(!showDeleteModel);
  };

  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (nutritions || []).slice(start, end).length;

  if (isLoadingNutritins) {
    return <Loader isLoading={isLoadingNutritins} />;
  }
  return (
    <Container maxWidth="xxl">
      <Stack gap={3}>
        <Stack direction="row" alignItems="center">
          <Typography component="h2" fontSize="18px" fontWeight="600" pr={2}>
            Nutrtion Plans
          </Typography>
          <Typography
            component="h2"
            fontSize="18px"
            fontWeight="600"
            color="#999999"
            pl={2}
            borderLeft="2px solid #D9D9D9"
            lineHeight="10px"
          >
            {" "}
            {(nutritions || []).length}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TextField
            placeholder="Rechercher un nutrtion Plan"
            name="searchNutrtionPlan"
            sx={{
              width: { sm: "100%", md: "414px" },
              ".MuiInputBase-root": {
                background: "#F1F1F1",
                borderRadius: "40px",
              },
              ".MuiInputBase-input": {
                height: "10px",
              },
            }}
            onChange={(e) => dispatch(searchNutritions(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanAllNutritionData());
              dispatch(getInitialNutrition());
              navigate("/nutrition/programs/create");
            }}
          >
            {" "}
            + créer un programme
          </Button>
        </Stack>
        <Stack gap={1} sx={{ minHeight: "700px" }}>
          <NutritionTitles />
          {(nutritions || []).slice(start, end).map((nutrition, index) => (
            <NutritionItem
              toggleDeleteModel={toggleDeleteModel}
              nutrition={nutrition}
              key={index}
            />
          ))}
        </Stack>
        {(nutritions || []).length === 0 && !isLoadingNutritins && (
          <h5>No Data Available</h5>
        )}
        {(nutritions || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start + 1}-{start + currentPageItems} sur{" "}
              {nutritions.length} results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(nutritions.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer le programme"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.name}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        maxWidth="sm"
        handleClose={() => toggleDeleteModel()}
        onConfirm={() => {
          dispatch(deleteNutrition(current._id)).then(() =>
            toggleDeleteModel()
          );
        }}
      />
    </Container>
  );
};

export default NutritionPlans;
