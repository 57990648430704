import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, ListItemAvatar, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/styles";
import ConfirmationModel from "components/ConfirmationModel";
import { connect } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addNutrition } from "store/actions/nutritions";
import { searchRecipes } from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const AddRecipeModel = (props) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = props.formik;
  const { current } = props;
  const programData = useSelector((state) => state.recipes.recipesLists);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);

  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  let exercisesList = activeElement ? activeElement["meals"] : [];

  return (
    <ConfirmationModel
      showActions={false}
      isOpen={props.isOpen || false}
      title={props.title ? props.title : "Add Exercise"}
      maxWidth="sm"
      body={
        <>
          <TextField
            placeholder="Search recipes"
            variant="outlined"
            fullWidth={true}
            size="small"
            onChange={(e) => dispatch(searchRecipes(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />

          {(programData || []).length === 0 ? (
            <span>Not Available</span>
          ) : (
            <Box
              sx={{
                border: "1px solid #CCCCCC",
                borderRadius: "12px",
                overflow: "hidden",
              }}
              mt={3}
            >
              <Demo>
                <List>
                  {(programData || []).map((program, index) => {
                    const element = {
                      week: activeWeek,
                      groupIndex: current,
                      exercisesList: exercisesList[current].recipes || [],
                    };
                    return (
                      <ListItem
                        sx={{ paddingRight: "70px" }}
                        key={index}
                        secondaryAction={
                          <>
                            {program.high_calories && (
                              <Box
                                title="High Calories"
                                component="span"
                                sx={{
                                  paddingRight: 0,
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  paddingTop: "5px",
                                }}
                              >
                                <ArrowCircleUpIcon htmlColor="#D35656" />
                              </Box>
                            )}
                            <IconButton
                              edge="end"
                              onClick={() => {
                                props.toggle();
                                dispatch(
                                  addNutrition(program, element, setFieldValue)
                                );
                              }}
                            >
                              <AddCircleIcon htmlColor="#39A894" />
                            </IconButton>
                          </>
                        }
                      >
                        <ListItemAvatar sx={{ display: "flex" }}>
                          <img
                            src={program.thumbnail}
                            height="40px"
                            width="40px"
                            alt="gym.jpg"
                            style={{ objectFit: "contain" }}
                            onError={(event) => {
                              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
                              event.onerror = null;
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={program.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Demo>
            </Box>
          )}
        </>
      }
      handleClose={props.toggle}
    />
  );
};

export default connect(AddRecipeModel);
