import { Box, Button, Container, Grid, Typography } from "@mui/material";
import DetailProgram from "components/EditProgram/DetailProgram";
import ProgramParameter from "components/EditProgram/ProgramParameter";
import Loader from "components/Loader";
import history from "components/history";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getCurrentProgram,
  getProgramLists,
  setFormData,
  setProgramData,
} from "store/actions/program";
import usePrompt from "utils/hooks/usePrompt";
import { programs } from "utils/programs.utils";

const EditProgramPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const formData = useSelector((state) => state.programs.formData);
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePrompt(isFormDirty);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );

  useEffect(() => {
    dispatch(getProgramLists());
    if (id && _.isEmpty(location.state)) {
      dispatch(getCurrentProgram(id));
    }
  }, []);

  return (
    <Container maxWidth="xxl">
      <Loader isLoading={isLoadingProgramms} />
      <div style={{ display: isLoadingProgramms ? "none" : "" }}>
        <Formik
          initialValues={{ ...formData }}
          enableReinitialize={true}
          onSubmit={(values) => {
            let oldValues = { ...values };
            if (oldValues.sexe) {
              const isMale = _.some(oldValues.sexe, {
                name: "Homme",
              })
                ? 3
                : 4;
              const sexe = _.map(oldValues.sexe, "name");
              const available_primaire = _.map(
                oldValues.available_primaire,
                "name"
              );
              const zone_musculaire = _.map(oldValues.zone_musculaire, "name");

              oldValues.assignment = [
                programs.generateQuestionArray(sexe, 1),
                programs.generateQuestionArray(available_primaire, 2),
                programs.generateQuestionArray(zone_musculaire, isMale),
              ];
            }
            let data = _.pick(oldValues, programs.programsFields);
            data.categories = _.map(data.categories, "name");
            data.primary_muscle = _.map(data.primary_muscle, "name");
            data.secondary_muscle = _.map(data.secondary_muscle, "name");
            setIsFormDirty(false);
            dispatch(setFormData(values));
            dispatch(setProgramData(data));
            setTimeout(() => {
              history.push(`/fitness/programs/final${id ? "/" + id : ""}`);
            }, 500);
          }}
        >
          {(props) => {
            const { handleSubmit, dirty } = props;
            setIsFormDirty(dirty);
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography color="#222222" fontWeight="600" mb={3}>
                      DÉTAILS DU PROGRAMME
                    </Typography>
                    <DetailProgram />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography color="#222222" fontWeight="600" mb={3}>
                      Paramètres de niveau de programme
                    </Typography>
                    <ProgramParameter />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="end" mt={3}>
                  <Button type="submit" color="primary" variant="contained">
                    {" "}
                    CONTINUER
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default EditProgramPage;
