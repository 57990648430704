import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CreateProgramDays from "components/CreateProgramPage";
import ExerciseDetailModel from "components/CreateProgramPage/SeanceExercise/ExerciseDetailModel";
import SessionForm from "components/CreateProgramPage/SessionForm";
import { FormContext } from "components/FormContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getExercises } from "store/actions/exercise";
import {
  addWeek,
  copySessionData,
  deleteSession,
  duplicateThisWeek,
  getCurrentProgram,
  pasteSessionData,
  removeWeek,
  setActiveWeek,
} from "store/actions/program";
import ConfirmationModel from "../../components/ConfirmationModel";
import Loader from "../../components/Loader";

const StyledSelect = styled(Select)(({ theme }) => ({
  background:
    "linear-gradient(260.66deg, rgba(119, 66, 233, 0.12) 7.06%, rgba(199, 54, 235, 0.12) 92.94%)",
  fontSize: "12px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#7742E9",
  border: "0 none",
  borderRadius: "44px",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "12px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#7742E9",
}));

const CreateProgramPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { formikRef } = useContext(FormContext);
  const [open, setOpen] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);

  const copiedSession = useSelector((state) => state.programs.copiedSession);
  const totalWeek = useSelector((state) => state.programs.totalWeek);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const weeks = useSelector((state) => state.programs.programItem.weeks);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );
  let isDeleteSessionDisabled = true;
  if (weeks[activeWeek + 1] && weeks[activeWeek + 1][activeDay + 1]?.session) {
    isDeleteSessionDisabled = false;
  }

  const toggle = (day) => {
    if (open.includes(day)) {
      const index = open.indexOf(day);
      const data = open;
      data.splice(index, 1);
      setOpen([...data]);
    } else {
      setOpen((prev) => [...prev, day]);
    }
  };

  useEffect(() => {
    dispatch(getExercises());
    if (id && formikRef) {
      dispatch(getCurrentProgram(id, formikRef));
    }
  }, []);

  const handlePrevPaginate = () => {
    if (activeWeek !== 0) {
      dispatch(setActiveWeek(activeWeek - 1));
    }
  };

  const handleNextPaginate = () => {
    if (activeWeek !== totalWeek - 1) {
      dispatch(setActiveWeek(activeWeek + 1));
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "DUPLICATE_WEEK") {
      dispatch(duplicateThisWeek(formikRef, activeWeek));
    } else if (event.target.value === "ADD_WEEK") {
      dispatch(addWeek(formikRef, activeWeek));
    } else if (event.target.value === "REMOVE_WEEK") {
      dispatch(removeWeek(formikRef, activeWeek));
    }
  };
  const toggleDeleteModel = (ele = "") => {
    setShowDeleteSessionModal(!showDeleteSessionModal);
  };

  return (
    <>
      <Loader isLoading={isLoadingProgramms} />
      <Container
        maxWidth="xxl"
        style={{ display: isLoadingProgramms ? "none" : "" }}
      >
        <Box pb={2} borderBottom="1px solid #CCCCCC">
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <IconButton onClick={handlePrevPaginate}>
                <img src="/assets/logo/previous.svg" alt="previous" />
              </IconButton>
              <Typography fontWeight="700" fontFamily="Poppins" fontSize="24px">
                Week {activeWeek + 1} of {totalWeek}
              </Typography>
              <IconButton onClick={handleNextPaginate}>
                <img src="/assets/logo/next.svg" alt="previous" />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
              <FormControl sx={{ mt: 2, minWidth: 120 }} size="small">
                <StyledSelect
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={""}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Manage Weeks";
                    }
                  }}
                  onChange={handleChange}
                >
                  <StyledMenuItem value="DUPLICATE_WEEK">
                    Duplicate Week
                  </StyledMenuItem>
                  <StyledMenuItem value="ADD_WEEK">Add Week</StyledMenuItem>
                  <StyledMenuItem value="REMOVE_WEEK">
                    Remove Week
                  </StyledMenuItem>
                </StyledSelect>
              </FormControl>
            </Stack>
          </Stack>
          <CreateProgramDays />
        </Box>
        <Box py={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography
              component="h2"
              fontSize="16px"
              fontWeight="600"
              fontFamily="Poppins"
              display="flex"
              alignItems="center"
              gap="10px"
              pr={2}
            >
              <IconButton onClick={() => toggle(activeDay)}>
                {(open || []).includes(activeDay) ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </IconButton>
              Détail de la séance
            </Typography>
            <Stack direction="row" alignItems="center">
              {copiedSession && (
                <Button
                  sx={{ gap: 1, borderRadius: "8px" }}
                  variant="contained"
                  onClick={() => dispatch(pasteSessionData(formikRef))}
                  type="button"
                >
                  <ContentPasteIcon fontSize="small" />
                  COLLER la séance
                </Button>
              )}
              <Button
                sx={{ gap: 1, ml: 1 }}
                type="button"
                onClick={() => dispatch(copySessionData(formikRef))}
              >
                <ContentCopyIcon fontSize="small" />
                Copy Session
              </Button>
              <Button
                color="error"
                sx={{ gap: 1, ml: 1 }}
                disabled={isDeleteSessionDisabled}
                type="button"
                onClick={() => toggleDeleteModel()}
              >
                <DeleteOutlineIcon fontSize="small" />
                Delete session
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <SessionForm open={open} />
          </Stack>
        </Box>
        <ExerciseDetailModel />
      </Container>
      <ConfirmationModel
        isOpen={showDeleteSessionModal}
        title="Supprimer l’session"
        body="Vous êtes sur le point de supprimer un client. Cela le supprimera définitivement de votre bibliothèque."
        handleClose={toggleDeleteModel}
        onConfirm={() => dispatch(deleteSession(formikRef, toggleDeleteModel))}
      />
    </>
  );
};

export default CreateProgramPage;
