import axios from "axios";
import CustomRouter from "components/CustomBrowserRouter";
import Toast from "components/Toast";
import history from "components/history";
import React from "react";

import { Provider } from "react-redux";
import Routes from "routes";
import { FormProvider } from "./components/FormContext";
import store from "./store/store";
import ThemeProvider from "./theme";
import Cookie from "./utils/cookie";

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}/v2`;
axios.defaults.headers.common["Authorization"] = `Bearer ${Cookie.get("jwt")}`;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Cookie.delete("jwt");
      history.push("/login");
    }
    throw error;
  }
);

// DEPLOYED DATE 01/09/2023

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <FormProvider>
          <React.Suspense fallback="">
            <CustomRouter history={history}>
              <Routes />
            </CustomRouter>
          </React.Suspense>
        </FormProvider>
        <Toast />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
