import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePagination,
  deleteClientById,
  getClients,
  searchClients,
} from "store/actions/clients";
import ClientListBody from "./ClientsListBody";
import ClientListHeader from "./ClientsListHeader";

const MyClientsList = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.clients);
  const isLoadingClients = useSelector(
    (state) => state.clients.isLoadingClients
  );
  const currentPage = useSelector((state) => state.clients.currentPage);
  const [current, setCurrent] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (clients || []).slice(start, end).length;

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };
  useEffect(() => {
    dispatch(getClients());
  }, []);
  return (
    <Container maxWidth="xxl">
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        mt={2}
      >
        <TextField
          placeholder="Search clients..."
          name="search clients"
          sx={{
            width: { sm: "100%", md: "414px" },
            ".MuiInputBase-root": {
              background: "#F1F1F1",
              borderRadius: "40px",
            },
            ".MuiInputBase-input": {
              height: "10px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => dispatch(searchClients(e.target.value))}
        />
      </Stack>
      <Stack gap={1} mt={2}>
        <Loader isLoading={isLoadingClients} />
        {!isLoadingClients && <ClientListHeader />}
        {(clients || []).length === 0 && !isLoadingClients && (
          <h5>No Data Available</h5>
        )}

        {!isLoadingClients && (
          <Box sx={{ minHeight: "700px" }}>
            {(clients || []).slice(start, end).map((client, index) => (
              <ClientListBody
                key={client._id}
                client={client}
                toggleDeleteModel={toggleDeleteModel}
              />
            ))}
          </Box>
        )}
        {!isLoadingClients && (clients || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start + 1}-{start + currentPageItems} sur{" "}
              {clients.length} results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(clients.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer le client"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.first_name || "-"}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        handleClose={toggleDeleteModel}
        maxWidth="sm"
        onConfirm={() => {
          dispatch(deleteClientById(current._id, toggleDeleteModel)).then(() =>
            toggleDeleteModel()
          );
        }}
      />
    </Container>
  );
};

export default MyClientsList;
