import { Grid, styled } from "@mui/material";

const Header = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "12px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#999999",
}));

const ClientListHeader = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 16px",
      }}
    >
      <Header
        item
        xs={3}
        sx={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        client
      </Header>
      <Header item md={3}>
        ADRESSE E-MAIL
      </Header>
      <Header item md={2}>
        Abonnement
      </Header>
      <Header item md={2}>
        Membre Depuis
      </Header>
      <Header item md={1}>
        Statut
      </Header>
      <Header item md={1} sx={{ textAlign: "right" }}>
        Actions
      </Header>
    </Grid>
  );
};

export default ClientListHeader;
