import { Grid, styled } from "@mui/material";

const Header = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "12px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#999999",
}));

const ProgramTitles = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 16px",
      }}
    >
      <Header
        item
        xs={4}
        sx={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        Program
      </Header>
      <Header item md={2}>
        Status
      </Header>
      <Header item md={2}>
        In Explorer
      </Header>
      <Header item md={2}>
        e-Coach
      </Header>
      <Header item md={2} sx={{ textAlign: "right" }}>
        Actions
      </Header>
    </Grid>
  );
};

export default ProgramTitles;
