import CardImageUploader from "components/CardImageUploader";
import Field from "components/Field";
import { required } from "components/Field/validate";
import { connect } from "formik";

const NutritionDetails = ({ formik }) => {
  const { values } = formik;
  return (
    <>
      <CardImageUploader
        name="thumbnail"
        label={
          values.thumbnail ? "CHANGER LA MINIATURE" : "AJOUTER UNE MINIATURE"
        }
        apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
        thumbnail={values ? values.thumbnail : null}
      />
      <Field
        variant="outlined"
        type="text"
        label="TITRE DU PROGRAMME"
        placeholder="Entrer un titre"
        name="name"
        validate={required}
      />
      <Field
        variant="outlined"
        type="text"
        label="DESCRIPTION"
        placeholder="Entrer une description"
        name="description"
        multiline
        rows={3}
        validate={required}
      />
    </>
  );
};

export default connect(NutritionDetails);
