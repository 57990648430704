import axios from "axios";
import history from "components/history";
import _ from "lodash";
import { toast } from "react-hot-toast";
import { recipes } from "utils/recipe.utils";

export const SET_LOADING_RECIPES = "set_loading_recipes";
export const setLoadingRecipes = (response = false) => {
  return {
    type: SET_LOADING_RECIPES,
    payload: response,
  };
};

export const GET_RECIPES_LISTS = "get_recipes_lists";
export const getRecipeLists = () => {
  return (dispatch, getState) => {
    dispatch(setLoadingRecipes(true));
    return axios
      .get("/api/coach/recipes")
      .then((res) => {
        dispatch(setLoadingRecipes(false));
        dispatch({
          type: GET_RECIPES_LISTS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(setLoadingRecipes(false));
      });
  };
};

export const SEARCH_RECIPE_DATA = "search_recipe_data";
export const searchRecipes = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().recipes.searchedRecipes || []).filter(
      (e) => e.name.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_RECIPE_DATA,
      payload: filteredItem,
    });
  };
};

export const getCurrentRecipe = (id = "", formikRef) => {
  return (dispatch, getState) => {
    const { setFieldValue } = formikRef;
    dispatch(setLoadingRecipes(true));
    return axios
      .get(`/api/coach/recipes/${id}`)
      .then((res) => {
        dispatch(setLoadingRecipes(false));

        const response = res.data.data;
        const recipe_ingredients = _.map(
          response.recipe_ingredients,
          "description"
        );
        const recipe_nutritions = response.recipe_nutritions;
        const explore = _.map(response.explore, (n) => {
          return recipes.categorysList.find((e) => e.name === n);
        });
        setFieldValue("name", response.name);
        setFieldValue("difficulty_level", response.difficulty_level);
        setFieldValue("prep_time", response.prep_time);
        setFieldValue("cook_time", response.cook_time);
        setFieldValue("thumbnail", response.thumbnail);
        setFieldValue("video", response.video);
        setFieldValue("kcal", response.kcal);
        setFieldValue("steps", response.steps);
        setFieldValue("recipe_ingredients", recipe_ingredients);
        setFieldValue("protin", recipe_nutritions[0].value);
        setFieldValue("lipides", recipe_nutritions[1].value);
        setFieldValue("glucides", recipe_nutritions[2].value);
        setFieldValue("in_explorer", response.in_explorer);
        setFieldValue("high_calories", response.high_calories);
        setFieldValue("serving", response.serving);
        setFieldValue("meal_type", response.meal_type);
        setFieldValue("free", response.free);
        setFieldValue("explore", explore);
      })
      .catch((err) => {
        dispatch(setLoadingRecipes(false));
      });
  };
};

export const updateRecipe = (id, values = {}) => {
  return (dispatch, getState) => {
    return axios
      .patch(`/api/coach/recipes/${id}`, values)
      .then((res) => {
        toast.success("Recipe Updated Succssfully.");
        history.push("/nutrition/recipes");
      })
      .catch((err) => {
        toast.error("Error while Updating New Recipe");
      });
  };
};

export const deleteRecipe = (id) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/recipes/${id}`)
      .then((res) => {
        dispatch(getRecipeLists());
        toast.success("Recipe Deleted Succssfully.");
        history.push("/nutrition/recipes");
      })
      .catch((err) => {
        toast.error("Error while Deleting Recipe");
      });
  };
};

export const createNewRecipe = (values = {}) => {
  return (dispatch, getState) => {
    return axios
      .post("/api/coach/recipes", values)
      .then((res) => {
        toast.success("New Recipe Created Succssfully.");
        history.push("/nutrition/recipes");
      })
      .catch((err) => {
        toast.error("Error while Creating New Recipe");
      });
  };
};

export const PAGINATE_RECIPE_DATA = "paginate_recipe_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATE_RECIPE_DATA,
      payload: response,
    });
  };
};
