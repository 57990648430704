import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Cookie from "utils/cookie";
import history from "components/history";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
        paddingTop: APP_BAR_DESKTOP,
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        height: "100vh",
        display: "flex",
        alignItems: "center",
    },
}));

const LayoutContent = ({ element: ReactComponent }) => {
    const classes = useStyles();
    if (!Cookie.get("jwt")) {
        history.push("/login");
    }
    return (
        <MainStyle
            className={window.location.pathname === "/" ? classes.root : ""}
        >
            <ReactComponent />
        </MainStyle>
    );
};

export default LayoutContent;
