import "moment/locale/fr";
import moment from "moment/moment";

export function formatDate(date) {
  moment.locale("fr");
  if (date) {
    date = moment(date).format("DD MMMM YYYY");
  }
  return date;
}

export const fullName = (fName, lName) => {
  let fullName = "-";
  if (fName && lName) {
    fullName = `${fName} ${lName}`;
  } else if (fName && !lName) {
    fullName = fName;
  } else if (!fName && lName) {
    fullName = lName;
  }
  return fullName;
};
