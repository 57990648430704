import { combineReducers } from "redux";
import exercise from "store/reducer/exercise";
import program from "store/reducer/program";
import clients from "./reducer/clients";
import nutritions from "./reducer/nutritions";
import recipes from "./reducer/recipes";
import sessions from "./reducer/sessions";
import user from "./reducer/user";

const rootReducer = combineReducers({
  programs: program,
  exercise,
  recipes,
  nutritions,
  clients,
  user,
  sessions,
});

export default rootReducer;
