import { Grid, InputAdornment, List, Stack, Typography } from "@mui/material";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import usePrevious from "utils/hooks/usePrevious";

const SubTitleCard = ({ formik, index, exerciseIndex, exercise }) => {
  const { values, setFieldValue } = formik;
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [elements, setElements] = useState([0]);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};
  useEffect(() => {
    setFieldValue(
      `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].exercise_id`,
      exercise["_id"]
    );
  }, []);
  useEffect(() => {
    const count = `muscle${exerciseIndex}`;
    const program = values.week[activeWeek][activeDay];
    setElements(program[count] ? _.range(program[count]) : [0]);
  }, [values.week[activeWeek][activeDay][`muscle${exerciseIndex}`]]);
  // Reset sets values when changed muscle0 dropdown
  const currentSets =
    activeElement["exercise_groups"][exerciseIndex]["exercises"][index]["sets"];

  const prevElements = usePrevious(elements);
  const updateCurrentSets = (current, exerciseParam) => {
    if (!current && elements.length !== prevElements?.length) {
      setFieldValue(
        exerciseParam,
        activeElement["exercise_groups"][exerciseIndex]["exercises"][index][
          "sets"
        ][0]
      );
    }
    if (elements.length < prevElements?.length) {
      currentSets.splice(elements.length, prevElements.length);
      setFieldValue(
        `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].sets`,
        currentSets
      );
    }
  };

  const renderExercies = useMemo(() => {
    let list = [];
    (elements || []).forEach((ele, i) => {
      const exerciseParam = `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].sets[${i}]`;
      const current =
        activeElement["exercise_groups"][exerciseIndex]["exercises"][index][
          "sets"
        ][i];
      updateCurrentSets(current, exerciseParam);
      list.push(
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          mb={i < elements.length - 1 ? 2 : 0}
          key={i}
        >
          <Typography
            variant="subtitle1"
            color="#222222"
            fontWeight="500"
            fontSize="12px"
            textTransform="capitalize"
            width="45px"
            textAlign="center"
          >
            {"Set " + (i + 1)}
          </Typography>
          <Grid container columnSpacing={2}>
            <Grid item md={3}>
              <Field
                type="number"
                name={exerciseParam + ".weight"}
                placeholder="e.g. 10 kg"
                size="small"
                mb={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={3}>
              <Field
                type="number"
                name={exerciseParam + ".reps"}
                placeholder="e.g. 10 reps"
                size="small"
                mb={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">reps</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={3}>
              <Field
                type="number"
                placeholder="e.g. 30 Sec"
                name={exerciseParam + ".duration"}
                mb={0}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">timer</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={3}>
              <Field
                type="number"
                name={exerciseParam + ".rest"}
                placeholder="e.g. 10 repos"
                size="small"
                mb={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">repos</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      );
    });
    return list;
  }, [elements]);

  return (
    <List component="div" disablePadding onClick={(e) => e.stopPropagation()}>
      {renderExercies}
    </List>
  );
};

export default connect(SubTitleCard);
