import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePagination,
  cleanAllProgramData,
  deleteProgramById,
  getInitialProgram,
  getProgramLists,
  searchPrograms,
  setFormData,
} from "store/actions/program";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import ProgrammItem from "./ProgramItem";
import ProgramTitles from "./ProgramTitles";

const ProgrammsPage = () => {
  useDocumentTitle("Mes Programmes Fitness", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programms = useSelector((state) => state.programs.programms);
  const currentPage = useSelector((state) => state.programs.currentPage);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [showInfoModel, setInfoModel] = useState(false);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    dispatch(getProgramLists());
  }, []);

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };
  const props = { toggleDeleteModel, toggleInfoModel };
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (programms || []).slice(start, end).length;

  return (
    <Container maxWidth="xxl">
      <Stack gap={3}>
        <Stack direction="row" alignItems="center">
          <Typography component="h2" fontSize="18px" fontWeight="600" pr={2}>
            Programmes
          </Typography>
          <Typography
            component="h2"
            fontSize="18px"
            fontWeight="600"
            color="#999999"
            pl={2}
            borderLeft="2px solid #D9D9D9"
            lineHeight="10px"
          >
            {(programms || []).length}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TextField
            placeholder="Rechercher un programme"
            name="search programme"
            sx={{
              width: { sm: "100%", md: "414px" },
              ".MuiInputBase-root": {
                background: "#F1F1F1",
                borderRadius: "40px",
              },
              ".MuiInputBase-input": {
                height: "10px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(searchPrograms(e.target.value))}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              dispatch(setFormData());
              navigate("/fitness/programs/create");
            }}
          >
            {" "}
            + créer un Programme
          </Button>
        </Stack>
        <Stack gap={1}>
          <Loader isLoading={isLoadingProgramms} />
          {!isLoadingProgramms && <ProgramTitles />}
          {(programms || []).length === 0 && !isLoadingProgramms && (
            <h5>No Data Available</h5>
          )}
          {!isLoadingProgramms && (
            <Box sx={{ minHeight: "700px" }}>
              {(programms || []).slice(start, end).map((program, index) => (
                <ProgrammItem key={index} program={program} {...props} />
              ))}
            </Box>
          )}
        </Stack>
        {!isLoadingProgramms && (programms || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start + 1}-{start + currentPageItems} sur{" "}
              {programms.length} results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(programms.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer le programme"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.name}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(deleteProgramById(current._id, toggleInfoModel)).then(() =>
            toggleDeleteModel()
          );
        }}
      />
      <ConfirmationModel
        showActions={false}
        isOpen={showInfoModel}
        title="Supprimer l’exercice"
        body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
        handleClose={toggleInfoModel}
      />
    </Container>
  );
};

export default ProgrammsPage;
