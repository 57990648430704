import {
  GET_CLIENTS_DATA,
  LOADING_CLIENTS_DATA,
  PAGINATION_CLIENTS_DATA,
  SEARCH_CLIENTS_DATA,
  SET_CURRENT_CLIENT_DETAILS,
} from "store/actions/clients";
export default function clients(state = null, action) {
  if (state == null) {
    return {
      searchedClients: [],
      clients: [],
      currentPage: 1,
      totalItem: 0,
      isLoadingClients: false,
      loggedInUser: {},
      currentClientDetails: {},
    };
  }
  switch (action.type) {
    case GET_CLIENTS_DATA:
      return {
        ...state,
        clients: action.payload,
        searchedClients: action.payload,
      };
    case PAGINATION_CLIENTS_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SEARCH_CLIENTS_DATA:
      return {
        ...state,
        currentPage: 1,
        clients: action.payload,
      };
    case LOADING_CLIENTS_DATA:
      return {
        ...state,
        isLoadingClients: action.payload,
      };
    case SET_CURRENT_CLIENT_DETAILS:
      return {
        ...state,
        currentClientDetails: action.payload,
      };
    default:
      return state;
  }
}
