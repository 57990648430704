import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Grid, IconButton, styled, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewRecipe, getRecipeLists } from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #CCCCCC",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    fontWeight: 500,
    fontSize: "12px",
  },
}));

function checkAndAddCopy(str) {
  if (str.includes("copy")) {
    const regex = /copy\s*(\d*)$/;
    const match = str.match(regex);
    let count = 1;
    if (match && match[1]) {
      count = parseInt(match[1], 10);
    }
    str = str.replace(regex, "");

    return str.trim() + " copy " + (count + 1);
  } else {
    return str.trim() + " copy";
  }
}

const Status = styled(Typography)(({ theme }) => ({
  background: theme.palette.gradients.primary,
  fontSize: "12px",
  padding: "4px 12px",
  color: "#FFFFFF",
  borderRadius: "44px",
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const ExcerciceItem = ({ toggleDeleteModel, toggleInfoModel, recipe }) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const classes = useStyle();
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => naviagate(`/nutrition/recipes/${recipe["_id"]}`)}
    >
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <img
            src={recipe.thumbnail}
            alt="profile"
            className={classes.img}
            onError={(event) => {
              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
              event.onerror = null;
            }}
          />
          {recipe.name}
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "center" }}>
          <Status component="span">ACTIVE</Status>
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "center" }}>
          {recipe.in_explorer ? "Yes" : "No"}
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "center" }}>
          {recipe.high_calories ? "Yes" : "No"}
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => naviagate("/fitness/programs/1")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              recipe.name = checkAndAddCopy(recipe.name);
              dispatch(createNewRecipe(recipe)).then(() =>
                dispatch(getRecipeLists())
              );
            }}
          >
            <ContentCopyIcon />
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(recipe);
            }}
          >
            <DeleteOutlineIcon sx={{ fontSize: "28px" }} />
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default ExcerciceItem;
