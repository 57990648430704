import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import NavHeader from "./NavHeader";

// ----------------------------------------------------------------------//

const DRAWER_WIDTH = 271;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    borderBottom: "1px solid #CCCCCC",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    color: "black",
    [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("lg")]: {
        minHeight: APPBAR_DESKTOP,
        padding: "20px 24px",
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: "0px none",
    },
}));

// ----------------------------------------------------------------------//

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const classes = useStyles();
    const location = useLocation();
    const [isWelcomeScreen, setIsWelcomeScreen] = useState(false);
    useEffect(() => {
        if (location.pathname === "/") {
            setIsWelcomeScreen(true);
        } else {
            setIsWelcomeScreen(false);
        }
    }, [location.pathname]);

    return (
        <RootStyle className={location.pathname === "/" ? classes.root : ""}>
            <ToolbarStyle>
                <IconButton
                    onClick={onOpenSidebar}
                    sx={{
                        mr: 1,
                        color: "text.primary",
                        display: { lg: "none" },
                    }}
                >
                    <DensityMediumIcon />
                </IconButton>
                {!isWelcomeScreen && <NavHeader location={location.pathname} />}
                <Box sx={{ flexGrow: 1 }} />
            </ToolbarStyle>
        </RootStyle>
    );
}
