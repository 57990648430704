import React from 'react';
import Field from "components/Field";

const IngrediantsContent = () => {
    return (
        <Field
            type="list"
            label={`Ingredient`}
            placeholder="Chipotle Sauce"
            name={`recipe_ingredients`}
            size="small"
            isExpandable={true}
            mb={0}
        />
    );
};

export default IngrediantsContent;
