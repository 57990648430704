import axios from "axios";
import toast from "react-hot-toast";
export const GET_CLIENTS_DATA = "get_clients_data";
export const getClients = () => {
  return (dispatch) => {
    dispatch(loadingClients(true));
    axios
      .get("/api/coach/users")
      .then((res) => {
        dispatch({
          type: GET_CLIENTS_DATA,
          payload: res.data.data,
        });
        dispatch(loadingClients(false));
      })
      .catch(() => {
        toast.error("Having issue with fetching Clients Data");
        dispatch(loadingClients(false));
      });
  };
};

export const PAGINATION_CLIENTS_DATA = "pagination_clients_data";
export const changePagination = (response = 1) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_CLIENTS_DATA,
      payload: response,
    });
  };
};

export const SEARCH_CLIENTS_DATA = "search_clients_data";
export const searchClients = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().clients.searchedClients || []).filter(
      (e) =>
        e.first_name?.toLowerCase().includes(response.toLowerCase()) ||
        e.last_name?.toLowerCase().includes(response.toLowerCase()) ||
        e.email?.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_CLIENTS_DATA,
      payload: filteredItem,
    });
  };
};

export const LOADING_CLIENTS_DATA = "loading_clients_data";
export const loadingClients = (response = false) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_CLIENTS_DATA,
      payload: response,
    });
  };
};

export const deactivateClientById = (id = "", toggleDeactivateModal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/coach/users/${id}`)
      .then(() => {
        toggleDeactivateModal();
        toast.success("Client deactivated successfully!");
      })
      .catch((err) => {
        toggleDeactivateModal();
        toast.error("Error while deactivating client!");
      });
  };
};

export const deleteClientById = (id = "", toggleDeleteModal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/users/${id}`)
      .then(() => {
        dispatch(getClients());
        toast.success("Client deleted successfully!");
      })
      .catch((err) => {
        toggleDeleteModal();
        toast.error("Error while deleting client!");
      });
  };
};

export const resetClientPassword = (email = "", toggleResetPassword) => {
  const body = {
    email,
  };
  return axios
    .post(`/api/auth/forgotpassword`, body)
    .then(() => {
      toast.success("Reset client password successfully.");
      toggleResetPassword();
    })
    .catch((err) => {
      toast.error("Error while reseting client password!");
    });
};

export const SET_CURRENT_CLIENT_DETAILS = "set_current_client_details";
export const getClientDetails = (id = "") => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/users/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CURRENT_CLIENT_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        toast.error("Error While fetching client details!");
      });
  };
};
