import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { adminConfig } from "../config";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: "white",
  fontWeight: 600,
  borderRadius: "48px",
  "&:hover": {
    background: "#444444",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItemLabel(props) {
  return (
    <ListItemText
      disableTypography
      primary={props.title}
      sx={{
        textTransform: "uppercase",
        padding: "10px 20px",
        color: "#7A7A7A",
        fontWeight: 600,
        mt: 3,
        mb: 1,
      }}
    />
  );
}
function NavItem({ item, active }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActiveRoot = active(item.references);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isActiveRoot) {
      setOpen(isActiveRoot);
    }
  }, [isActiveRoot]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    background: "#444444",
    borderRadius: "48px",
  };

  if (item.headerLabel) {
    return <NavItemLabel title={item.headerLabel} />;
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            height: "48px",
            fontWeight: 500,
            color: "white",
            mb: 2,
            ...(isActiveRoot && {}),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? (
            <KeyboardArrowUpIcon sx={{ mr: 2 }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ mr: 2 }} />
          )}
        </ListItemStyle>
        <Collapse sx={{ mb: 2 }} in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, idx) => {
              const { title, path, references } = item;
              const isActiveSub = active(references);

              return (
                <ListItemStyle
                  sx={{
                    paddingLeft: "56px",
                    mb: 1,
                    mt: 1,
                    height: 40,
                    ...(isActiveSub && activeRootStyle),
                  }}
                  key={idx}
                  onClick={() => {
                    navigate(path);
                  }}
                >
                  <ListItemText
                    disableTypography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    primary={title}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        height: "3rem",
        position: "relative",
        mb: 2,
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

const NavSection = () => {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!path.includes(pathname) : false);
  return (
    <Box>
      <List disablePadding sx={{ p: 1, mt: 2, px: 3 }}>
        {adminConfig.map((item, idx) => (
          <NavItem key={idx} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};

export default NavSection;
