import React from 'react';
import {Button, Grid, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Field from "components/Field";
import {email} from "components/Field/validate";
import {Formik} from "formik";
import axios from "axios";
import toast from "react-hot-toast";

const ForgetPasswordPage = () => {
    const navigate = useNavigate();
    return (
        <Grid
            item
            lg={7}
            md={12}
            sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
            }}
        >
            <Stack
                sx={{width: "100%",gap: {xs: "30px", md: "50px"}}}
                display="flex"
                alignItems="center"
                gap="96px"
            >
                <Stack>
                    <Typography
                        component="span"
                        sx={{color: "primary.main", fontWeight: 400, fontSize: "12px", cursor: "pointer"}}
                    > <img src="/assets/logo/Logo.png" alt="google" height="47"/>
                    </Typography>
                </Stack>
                <Stack>
                    <Typography
                        component="span"
                        variant="h3"
                        sx={{mt: 3}}
                        fontWeight="700"
                        fontSize="24px"
                        textAlign="center"
                    > Forgot Password
                    </Typography>
                    <Typography
                        component="span"
                        variant="h3"
                        sx={{mt: 3}}
                        fontWeight="400"
                        fontSize="16px"
                        textAlign="center"
                    >
                        Not a problem! Enter your email address associated with your account and we’ll send you a secure link to reset your password.
                    </Typography>
                </Stack>
                <Stack width="100%">
                    <Formik
                        initialValues={{
                            email:"",
                        }}
                        onSubmit={(values,{setSubmitting}) => {
                            setSubmitting(true);
                            axios.post(`/api/auth/forgotpassword`,values)
                                .then((res)=>{
                                    setSubmitting(false);
                                    toast.success(res?.data?.data?.message || "Password Reset Requested.");
                                    navigate("/login")
                                })
                                .catch((err)=>{
                                    setSubmitting(false);
                                    toast.error(err?.response?.data?.message || "Invalid Access.");
                                })
                        }}
                    >
                        {props => {
                            const {handleSubmit,isSubmitting} = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        type="email"
                                        label="Email"
                                        placeholder="Enter email here..."
                                        name="email"
                                        validate={email}
                                    />
                                    <Stack width="100%" mb={2}>
                                        <Button
                                            type="email"
                                            variant="contained"
                                            disabled={isSubmitting}
                                        > submit
                                        </Button>
                                    </Stack>
                                </form>
                            )
                        }}
                    </Formik>
                    <Typography
                        component="span"
                        textAlign="center"
                        sx={{
                            fontWeight: 400,
                            textDecoration:"underline",
                            fontSize: "16px",
                            cursor: "pointer",
                            color:"#C736EB"
                        }}
                        onClick={()=>navigate("/login")}
                    > Cancel
                    </Typography>
                </Stack>
            </Stack>
        </Grid>
    );
};

export default ForgetPasswordPage;
