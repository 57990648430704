export const recipes = {
  categorysList: [
    { id: 1, name: "Nouveautés" },
    { id: 2, name: "Top 10" },
    { id: 8, name: "Vegan" },
    { id: 9, name: "Végétarien" },
    { id: 3, name: "Petit déjeuner" },
    { id: 4, name: "Dîner" },
    { id: 5, name: "Collation" },
    { id: 6, name: "Dessert" },
    { id: 7, name: "Déjeuner" },
  ],
  initialValues: {
    name: null,
    prep_time: null,
    cook_time: null,
    // tags: null,
    thumbnail: null,
    video: null,
    difficulty_level: "",
    serving: null,
    meal_type: "",
    explore: null,
    in_explorer: false,
    high_calories: false,
    free: false,
    recipe_ingredients: [""],
    steps: [""],

    muscle2: null,

    kcal: null,
    protin: null,
    lipides: null,
    glucides: null,
    switch2: false,
  },
};
