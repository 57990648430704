import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import ConfirmationModel from "components/ConfirmationModel";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePagination,
  cleanupExercise,
  getExercises,
  searchExercise,
} from "store/actions/exercise";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import Loader from "../../components/Loader";
import ExcerciceItem from "./ExecrciceItem";

const HomePage = () => {
  useDocumentTitle("Mes Exercices", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exercises = useSelector((state) => state.exercise.exercises);
  const currentPage = useSelector((state) => state.exercise.currentPage);
  const isLoadingExercise = useSelector(
    (state) => state.exercise.isLoadingExercise
  );

  useEffect(() => {
    dispatch(getExercises());
  }, []);

  const [showDeleteModel, setDeleteModel] = useState(false);
  const [current, setCurrent] = useState({});
  const [showInfoModel, setInfoModel] = useState(false);

  const toggleDeleteModel = (item = {}) => {
    setDeleteModel(!showDeleteModel);
    setCurrent(item);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };

  const deleteItem = () => {
    axios
      .delete(`/api/coach/exercises/${current._id}`)
      .then((res) => {
        dispatch(getExercises());
        toggleDeleteModel();
        toast.success("Exercises Deleted Successfully.");
      })
      .catch((err) => {
        toggleInfoModel();
      });
  };

  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (exercises || []).slice(start, end).length;

  return (
    <Container maxWidth="xxl">
      <Stack gap={3}>
        <Stack direction="row" alignItems="center">
          <Typography component="h2" fontSize="18px" fontWeight="600" pr={2}>
            Exercises
          </Typography>
          <Typography
            component="h2"
            fontSize="18px"
            fontWeight="600"
            color="#999999"
            pl={2}
            borderLeft="2px solid #D9D9D9"
            lineHeight="10px"
          >
            {(exercises || []).length}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TextField
            placeholder="Rechercher un exercice"
            name="searchExercice"
            sx={{
              width: { sm: "100%", md: "414px" },
              ".MuiInputBase-root": {
                background: "#F1F1F1",
                borderRadius: "40px",
              },
              ".MuiInputBase-input": {
                height: "10px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(searchExercise(e.target.value))}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanupExercise());
              navigate("/fitness/exercices/new");
            }}
          >
            {" "}
            + créer UN EXERCICE
          </Button>
        </Stack>
        <Stack gap={1}>
          <Loader isLoading={isLoadingExercise} />
          {!isLoadingExercise && (
            <Box sx={{ minHeight: "700px" }}>
              {(exercises || []).slice(start, end).map((item, index) => (
                <ExcerciceItem
                  key={index}
                  item={item}
                  toggleDeleteModel={toggleDeleteModel}
                  toggleInfoModel={toggleInfoModel}
                />
              ))}
            </Box>
          )}
        </Stack>
        {(exercises || []).length === 0 && !isLoadingExercise && (
          <h5>No Data Available</h5>
        )}
        {!isLoadingExercise && (exercises || []).length > 10 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="#000000" fontSize="12px" fontWeight="500">
              Showing {start + 1}-{start + currentPageItems} sur{" "}
              {exercises.length} results
            </Typography>
            <Pagination
              page={currentPage}
              onChange={(page, value) => dispatch(changePagination(value))}
              count={Math.ceil(exercises.length / 10)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              sx={{
                background: "#F1F1F1",
                padding: 1,
                borderRadius: 2,
                ".Mui-selected": {
                  background:
                    "linear-gradient(260.66deg, #7742E9 7.06%, #C736EB 92.94%)",
                  color: "white !important",
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      </Stack>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer l’exercice"
        body={[
          "Vous êtes sur le point de supprimer",
          <strong> {current?.name}</strong>,
          ". Cela le supprimera définitivement de votre bibliothèque.",
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          deleteItem();
        }}
      />
      <ConfirmationModel
        showActions={false}
        isOpen={showInfoModel}
        title="Supprimer l’exercice"
        body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
        handleClose={toggleInfoModel}
      />
    </Container>
  );
};

export default HomePage;
