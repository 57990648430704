import { Grid, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "utils/cookie";
import ForgetPasswordPage from "./ForgetPasswordPage";
import LoginForm from "./LoginForm";

const StyledImage = styled(Grid)(() => ({
  "& img": {
    height: "100vh",
    width: "100%",
  },
}));

const AuthPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookie.get("jwt")) {
      navigate("/");
    }
  }, []);
  const coverImage =
    window.location.pathname === "/forget-password"
      ? "forgetPasswordCover.jpg"
      : "loginImage.jpg";

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <StyledImage item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src={`/assets/images/${coverImage}`}
          alt="loginImage"
          style={{ objectFit: "fill", objectPosition: "left" }}
        />
      </StyledImage>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          height: "calc(100vh - 0px)",
          padding: { xs: "10px 20px", md: "20px 0" },
          overflowY: "auto",
          background: "white",
        }}
        display="flex"
      >
        {window.location.pathname === "/forget-password" && (
          <ForgetPasswordPage />
        )}
        {window.location.pathname === "/login" && <LoginForm />}
      </Grid>
    </Grid>
  );
};

export default AuthPage;
