import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, IconButton, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #CCCCCC",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "#222222",
    fontWeight: 500,
    fontSize: "12px",
  },
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const ExcerciceItem = ({ toggleDeleteModel, item }) => {
  const naviagate = useNavigate();
  const classes = useStyle();
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      action={
        <>
          <IconButton color="inherit" size="small">
            <EditIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(item);
            }}
          >
            <DeleteOutlineIcon sx={{ fontSize: "28px" }} />
          </IconButton>
        </>
      }
      onClick={() => naviagate(`/fitness/exercices/${item._id}`)}
    >
      <img
        src={item.thumbnail}
        alt="profile"
        className={classes.img}
        onError={(event) => {
          event.target.src = THUMBNAIL_PLACEHOLDER;
          event.onerror = null;
        }}
        style={{ objectFit: "cover", borderRadius: "10px" }}
      />
      {item.name}
    </StyledAlert>
  );
};

export default ExcerciceItem;
