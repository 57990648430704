import {Box, IconButton, Stack} from "@mui/material";
import Field from "../index";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from "@mui/icons-material/AddCircle";

const labels = {
    "recipe_ingredients": "Ingredient",
    "steps": "STEP",
};

const ListInput = (props) => {
    const {form, push, remove} = props;
    return (
        <Box gap={3} display="grid">
            {(form.values[props.name] || []).map((ele, index) => {
                return (
                    <Stack direction="row" gap={2} key={index}>
                        <Stack width="100%">
                            <Field
                                type="text"
                                label={`${labels[props.name]} ${index + 1}`}
                                name={`${props.name}.${index}`}
                                size="small"
                                mb={0}
                                rows={props.name === "steps" ? 3 : 1}
                                multiline
                            />
                        </Stack>
                        <IconButton color="inherit" size="small" onClick={() => remove(index)}>
                            <DeleteOutlineIcon/>
                        </IconButton>
                    </Stack>
                )
            })}
            <Stack direction="row" justifyContent="center">
                <IconButton
                    edge="end"
                    onClick={() => push("", "")}
                >
                    <AddCircleIcon htmlColor="#39A894"/>
                </IconButton>
            </Stack>
        </Box>
    )
}

export default ListInput