import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import { multiSelect, required } from "components/Field/validate";
import Loader from "components/Loader";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createExercises, getCurrentExercises } from "store/actions/exercise";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import { muscle } from "utils/gym.utils";
import usePrompt from "utils/hooks/usePrompt";

const EditExercice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePrompt(isFormDirty);
  const currentExercise = useSelector(
    (state) => state.exercise.currentExercise
  );
  const isLoadingExercise = useSelector(
    (state) => state.exercise.isLoadingExercise
  );

  const [showImageModel, setImageModel] = useState(false);
  const [selectedFile, setselectedFile] = useState("thumbnail");

  useEffect(() => {
    if (id) {
      dispatch(getCurrentExercises(id));
    }
  }, []);

  const toggleImageModel = (file = "thumbnail") => {
    setImageModel(!showImageModel);
    setselectedFile(file);
  };

  const getOptionValue = (primary_muscle) => {
    const data = primary_muscle
      ? _.map(primary_muscle.split(","), (n) => {
          return muscle.find((e) => e.name === n);
        })
      : [];
    return data;
  };

  function removeHtmlTags(text = "") {
    const element = document.createElement("div");
    element.innerHTML = text;
    return element.textContent || element.innerText || "";
  }

  const initialValues = () => {
    const data = {
      name: currentExercise ? currentExercise.name : "",
      primary_muscle: currentExercise
        ? getOptionValue(currentExercise.primary_muscle)
        : "",
      secondary_muscle: currentExercise
        ? getOptionValue(currentExercise.secondary_muscle)
        : "",
      thumbnail: currentExercise ? currentExercise.thumbnail : "",
      video: currentExercise ? currentExercise.video : "",
      description: currentExercise
        ? removeHtmlTags(currentExercise.description)
        : "",
    };

    return data;
  };

  if (isLoadingExercise) {
    return <Loader isLoading={isLoadingExercise} />;
  }
  return (
    <Container maxWidth="xxl">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues()}
        onSubmit={(values) => {
          setIsFormDirty(false);
          const data = { ...values };
          data.primary_muscle = _.map(data.primary_muscle, "name").join(",");
          data.secondary_muscle = _.map(data.secondary_muscle, "name").join(
            ","
          );
          data.thumbnail = data.thumbnail
            ? data.thumbnail
            : THUMBNAIL_PLACEHOLDER;
          data.code = null;
          return dispatch(createExercises(data, id));
        }}
      >
        {(props) => {
          const { handleSubmit, isSubmitting, values, dirty } = props;
          if (!isSubmitting) setIsFormDirty(dirty);
          return (
            <form onSubmit={handleSubmit}>
              <Box gap={4} display="grid">
                <Box>
                  <Stack>
                    <TitleHeader number={1} title="Aperçu de l’exercice" />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CardImageUploader
                          name="thumbnail"
                          label={
                            currentExercise?.thumbnail
                              ? "CHANGER LA MINIATURE"
                              : "AJOUTER UNE MINIATURE"
                          }
                          thumbnail={
                            currentExercise ? currentExercise.thumbnail : null
                          }
                          apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardImageUploader
                          name="video"
                          label={
                            currentExercise?.video
                              ? "CHANGER LA VIDEO"
                              : "AJOUTER UNE VIDEO"
                          }
                          isVideo={true}
                          apiKey="/api/upload/coach/videos/exercises/new_video"
                        />
                      </Grid>
                    </Grid>
                    <Field
                      type="text"
                      label="TITRE DE L’EXERCICE"
                      placeholder="TITRE DE L’EXERCICE"
                      name="name"
                      validate={required}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          type="multiselect"
                          label="MUSCLES PRIMAIRE"
                          placeholder="sélectionner un muscle"
                          name="primary_muscle"
                          notched={true}
                          options={muscle}
                          validate={multiSelect}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          type="multiselect"
                          label="MUSCLES SECONDAIRE"
                          placeholder="sélectionner un muscle"
                          name="secondary_muscle"
                          options={muscle}
                          notched={true}
                          validate={multiSelect}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack>
                    <TitleHeader
                      number={2}
                      title="Conseils et instructions pour l'exercice"
                    />
                    <Stack>
                      <Field
                        type="text"
                        placeholder="Description"
                        label="Description"
                        name="description"
                        options={muscle}
                        notched={true}
                        rows={7}
                        multiline
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box display="flex" justifyContent="end">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Save Exercise
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>

      <ConfirmationModel
        showActions={false}
        maxWidth="sm"
        isOpen={showImageModel}
        title="Modifier la vignette"
        body={
          <Stack
            textAlign="center"
            sx={{
              background: "#F1F1F1",
              padding: { xs: "10px", sm: "50px" },
              borderRadius: "12px",
            }}
            onClick={() => document.querySelector("#file_uploader").click()}
          >
            <input
              type="file"
              accept={
                selectedFile === "thumbnail"
                  ? "image/png, image/jpeg"
                  : "video/mp4,video/x-m4v,video/*"
              }
              style={{ visibility: "hidden" }}
              id="file_uploader"
              multiple={false}
              onChange={(e) => {
                toggleImageModel();
              }}
            />
            <img src="/assets/logo/upload.svg" alt="upload" height="24px" />
            <Typography color="#7742E9" fontSize="14px" fontWeight="600" mt={1}>
              TÉLÉVERSER UN FICHIER
            </Typography>
            <Typography color="#999999" fontSize="12px" fontWeight="500" mt={1}>
              ou faites glisser et déposez le fichier ici
            </Typography>
            <Typography color="#999999" fontSize="12px" fontWeight="500" mt={1}>
              Only JPEG or PNG files up to 5 MB in size.
            </Typography>
          </Stack>
        }
        handleClose={toggleImageModel}
      />
    </Container>
  );
};

export default EditExercice;
