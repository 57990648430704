import { Grid, MenuItem } from "@mui/material";
import Field from "components/Field";
import { multiSelect, required } from "components/Field/validate";
import { connect } from "formik";
import { bmrValue, objectFit } from "../../../utils/gym.utils";

const ProgramFields = ({ formik }) => {
  const { values } = formik;
  return (
    <>
      <Field
        type="switch"
        label="Ajouter le programme à l’Algorythme"
        name="e_coach_program"
      />
      {values.e_coach_program && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Field
              type="select"
              label="BMR RANGE"
              placeholder="Select range"
              name="bmr_range"
              notched={true}
              validate={required}
            >
              {bmrValue.map((val, idx) => (
                <MenuItem value={val} key={idx}>
                  {val}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              type="multiselect"
              label="OBJECTIF"
              placeholder="Objectif"
              name="objectives"
              notched={true}
              options={objectFit}
              validate={multiSelect}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default connect(ProgramFields);
