import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CreateNutritionDays from "components/CreateNutritionDays";
import { FormContext } from "components/FormContext";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  copySessionData,
  duplicateThisWeek,
  getCurrentNutrition,
  pasteSessionData,
  setActiveWeekNutrition,
} from "store/actions/nutritions";
import Loader from "../../components/Loader";
import NutritionFinalForm from "./NutritionFinalForm";

const NutritionInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { formikRef } = useContext(FormContext);

  const totalWeek = useSelector((state) => state.nutritions.totalWeek);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const copiedSession = useSelector((state) => state.nutritions.copiedSession);
  const isLoadingNutrition = useSelector(
    (state) => state.nutritions.isLoadingNutritins
  );

  useEffect(() => {
    if (id) {
      dispatch(getCurrentNutrition(id, formikRef));
    }
  }, []);

  const handlePrevPaginate = () => {
    if (activeWeek !== 0) {
      dispatch(setActiveWeekNutrition(activeWeek - 1));
    }
  };

  const handleNextPaginate = () => {
    if (activeWeek !== totalWeek - 1) {
      dispatch(setActiveWeekNutrition(activeWeek + 1));
    }
  };

  return (
    <>
      <Loader isLoading={isLoadingNutrition} />
      <Container
        maxWidth="xxl"
        sx={{ display: isLoadingNutrition ? "none" : "" }}
      >
        <Box pb={2} borderBottom="1px solid #CCCCCC">
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <IconButton onClick={handlePrevPaginate}>
                <img src="/assets/logo/previous.svg" alt="previous" />
              </IconButton>
              <Typography fontWeight="700" fontFamily="Poppins" fontSize="24px">
                Week {activeWeek + 1} of {totalWeek}
              </Typography>
              <IconButton onClick={handleNextPaginate}>
                <img src="/assets/logo/next.svg" alt="previous" />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Button
                sx={{ gap: 1 }}
                onClick={() =>
                  dispatch(duplicateThisWeek(formikRef, activeWeek))
                }
              >
                <ContentCopyIcon fontSize="small" />
                DUPPLIQUER à toutes les semaines
              </Button>
            </Stack>
          </Stack>
          <CreateNutritionDays />
        </Box>
        <Box py={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography
              component="h2"
              fontSize="16px"
              fontWeight="600"
              fontFamily="Poppins"
              pr={2}
            >
              details du menu de la journée
            </Typography>
            <Stack direction="row" alignItems="center">
              {copiedSession && (
                <Button
                  sx={{ gap: 1, borderRadius: "8px" }}
                  variant="contained"
                  onClick={() => dispatch(pasteSessionData(formikRef))}
                  type="button"
                >
                  <ContentPasteIcon fontSize="small" />
                  COLLER la séance
                </Button>
              )}
              <Button
                sx={{ gap: 1, ml: 1 }}
                onClick={() => dispatch(copySessionData(formikRef))}
              >
                <ContentCopyIcon fontSize="small" />
                Copy Session
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <NutritionFinalForm />
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default NutritionInfo;
