import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {setActiveDay} from 'store/actions/program';

const CreateProgramDays = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState([])
    const activeDay = useSelector((state) => state.programs.activeDay);
    const programItem = useSelector((state) => state.programs.programItem);
    const activeWeek = useSelector((state) => state.programs.activeWeek);

    const toggleButton = (response) => {
        dispatch(setActiveDay(response));
    }

    useEffect(() => {
        if (programItem?.weeks) {
            if (programItem["weeks"][activeWeek + 1]) {
                setValues(Object.keys((programItem["weeks"][(activeWeek + 1)] || {})))
            } else {
                let program = programItem["weeks"];
                program[activeWeek + 1] = {};
                setValues(Object.keys((program[(activeWeek + 1)] || {})))
            }
        }
    }, [programItem, activeWeek, activeDay]);

    return (
        <Stack my={2}>
            <Typography component="h2" fontSize="16px" fontWeight="600" fontFamily='Poppins' pr={2}>
                Select Session
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap="8px" justifyContent="space-between" mt={2}>
                {[1, 2, 3, 4, 5, 6, 7].map((ele, index) => (
                    <Button
                        color={(activeDay === index || values.includes(String(ele))) ? "primary" : "info"}
                        key={index}
                        variant={(activeDay === index || !values.includes(String(ele))) ? "contained" : "outlined"}
                        onClick={() => toggleButton(index)}
                        sx={{flex: 1, borderRadius: "8px", boxShadow: "none"}}
                    > DAY {ele}
                    </Button>
                ))}
            </Stack>
        </Stack>
    );
};

export default CreateProgramDays;
