import React from 'react';
import {Grid, InputAdornment} from "@mui/material";
import Field from "components/Field";
import { required } from 'components/Field/validate';

const NutritionIntakes = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3} sm={6}>
                <Field
                    type="number"
                    label="CALORIES"
                    placeholder="e.g. 10 Kcal"
                    name="kcal"
                    size="small"
                    validate={required}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Kcal</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <Field
                    type="number"
                    label="PROTÉINE"
                    placeholder="e.g. 10"
                    name="protin"
                    size="small"
                    validate={required}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <Field
                    type="number"
                    label="LIPIDES"
                    placeholder="e.g. 10"
                    name="lipides"
                    size="small"
                    validate={required}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <Field
                    type="number"
                    label="GLUCIDES"
                    placeholder="e.g. 10"
                    name="glucides"
                    size="small"
                    validate={required}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default NutritionIntakes;
