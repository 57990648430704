import { Collapse, Grid, InputAdornment } from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import Field from "components/Field";
import { Formik, connect } from "formik";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getExercises } from "store/actions/exercise";
import {
  createNewProgram,
  getSessionList,
  setSessionCount,
  updateProgram,
  validateSession,
} from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import { explorerCategory } from "utils/gym.utils";
import { programs } from "utils/programs.utils";
import { multiSelect, required } from "../../Field/validate";
import { FormContext } from "../../FormContext";
import SeanceExercise from "../SeanceExercise";

const SessionForm = ({ formik, open, isIndependentSession = false }) => {
  const { formikRef } = useContext(FormContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const programItem = useSelector((state) => state.programs.programItem);
  const sessionCount = useSelector((state) => state.programs.sessionCount);
  const [totalWeeks, setTotalWeeks] = useState({});
  const [formData, setFormData] = useState();
  const initialProgramValue = useSelector(
    (state) => state.programs.initialProgramValue
  );
  const programData = useSelector((state) => state.programs.programData);
  useEffect(() => {
    if (_.isEmpty(programData) && !isIndependentSession) {
      navigate("/fitness/programs");
    }
    if (isIndependentSession && id) {
      const sessionObj = { 1: { 1: { session: id } } };
      dispatch(getSessionList(sessionObj, formikRef));
    } else if (isIndependentSession) {
      if (Object.keys(formikRef.current.values).length === 0) {
        navigate("/fitness/sessions");
      }
      dispatch(getExercises());
    }
  }, []);

  useEffect(() => {
    if (sessionCount > 0 && sessionCount === objKeyCount(totalWeeks)) {
      let data = programData;
      data.weeks = programs.convertKeys(programItem.weeks);
      data.thumbnail = data.thumbnail
        ? data.thumbnail
        : THUMBNAIL_PLACEHOLDER_GRAY;
      if (id) {
        dispatch(updateProgram(id, data));
      } else {
        dispatch(createNewProgram(data));
      }
    }
  }, [sessionCount]);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const objKeyCount = (object) => {
    let count = 0;
    _.forEach(object, async (value, key) => {
      count = count + Object.keys(value).length;
    });
    return count;
  };

  const handleSaveSingleSession = (values) => {
    const formVal = id
      ? { ...values, weeks: { 1: { 1: { session: id } } } }
      : values;
    dispatch(
      validateSession(formVal, 0, 0, formikRef, null, isIndependentSession)
    );
  };

  const handleSaveSessions = async (values) => {
    const weeksDummyObj = {};
    dispatch(setSessionCount(0));
    await _.forEach(values.week, async (value, key) => {
      await _.forEach(value, async (session, inx) => {
        if (session.name) {
          weeksDummyObj[parseInt(key) + 1] = {
            ...weeksDummyObj[parseInt(key) + 1],
            [inx + 1]: 1,
          };
          setTotalWeeks(weeksDummyObj);
          dispatch(
            validateSession(
              session,
              parseInt(key),
              inx,
              formikRef,
              values.weeks
            )
          );
        }
      });
    });
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialProgramValue}
      onSubmit={(values) => {
        if (isIndependentSession) {
          handleSaveSingleSession(values);
        } else {
          handleSaveSessions(values);
        }
      }}
    >
      {(props) => {
        const { handleSubmit, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Collapse
              in={open.includes(activeDay)}
              timeout="auto"
              sx={{ width: "100%", mt: 3 }}
              unmountOnExit
            >
              <Field
                type="text"
                label="SESSION TITLE"
                placeholder="Power Training E1"
                name={whereTo + `.name`}
                size="small"
                validate={required}
              />
              {values["week"] &&
                values["week"][activeWeek] &&
                values["week"][activeWeek][activeDay] && (
                  <CardImageUploader
                    name={whereTo + `.thumbnail`}
                    apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
                    thumbnail={values["week"][activeWeek][activeDay]?.thumbnail}
                  />
                )}
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <Field
                    type="number"
                    label="CALORIES"
                    placeholder="CALORIES"
                    name={whereTo + `.calories`}
                    size="small"
                    validate={required}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kcal</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    type="number"
                    label="SESSION TIME"
                    name={whereTo + `.duration`}
                    size="small"
                    validate={required}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">min</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    type="multiselect"
                    notched={true}
                    label="SELECT EQUIPMENT"
                    placeholder="SELECT EQUIPMENT"
                    name={whereTo + `.equipments`}
                    size="small"
                    validate={multiSelect}
                    options={programs.equipments}
                  />
                </Grid>
              </Grid>
            </Collapse>
            {isIndependentSession && (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Field
                    type="switch"
                    label="Ajouter le programme à l’Explorer"
                    name={whereTo + `.in_explorer`}
                  />
                  {values.week["0"][0].in_explorer && (
                    <>
                      <Field
                        type="multiselect"
                        label="SELECT EXPLORE PAGE CATEGORY"
                        name={whereTo + `.explorer_category`}
                        size="small"
                        validate={multiSelect}
                        notched={true}
                        options={explorerCategory}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            )}

            <SeanceExercise />
          </form>
        );
      }}
    </Formik>
  );
};

export default connect(SessionForm);
