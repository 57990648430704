import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCurrentNutrition,
  setNutritionData,
} from "store/actions/nutritions";
import usePrompt from "utils/hooks/usePrompt";
import Loader from "../../components/Loader";
import NutritionDetails from "./NutritionDetails";
import ProgramFields from "./ProgramFields";

const CreateNutritionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const nutritionData = useSelector((state) => state.nutritions.nutritionData);
  const isLoadingNutritins = useSelector(
    (state) => state.nutritions.isLoadingNutritins
  );
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePrompt(isFormDirty);

  useEffect(() => {
    if (id && _.isEmpty(location.state)) {
      dispatch(getCurrentNutrition(id));
    }
  }, []);

  if (isLoadingNutritins) {
    return <Loader isLoading={isLoadingNutritins} />;
  }
  return (
    <Container maxWidth="xxl">
      <Formik
        enableReinitialize={true}
        initialValues={nutritionData}
        onSubmit={(values) => {
          setIsFormDirty(false);
          dispatch(setNutritionData(values));
          setTimeout(() => {
            navigate(`/nutrition/programs/final${id ? `/${id}` : ""}`);
          }, 500);
        }}
      >
        {(props) => {
          const { dirty } = props;
          setIsFormDirty(dirty);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Typography
                    color="#222222"
                    fontWeight="600"
                    fontSize="12px"
                    mb={3}
                  >
                    DÉTAILS DU PROGRAMME
                  </Typography>
                  <NutritionDetails />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography
                    color="#222222"
                    fontWeight="600"
                    fontSize="12px"
                    mb={3}
                  >
                    PARAMÈTRES DE NIVEAU DE PROGRAMME
                  </Typography>
                  <ProgramFields />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="end" mt={3}>
                <Button type="submit" color="primary" variant="contained">
                  {" "}
                  CONTINUER
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CreateNutritionPage;
