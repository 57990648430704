import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import { useState } from "react";
import { useSelector } from "react-redux";
import { required } from "../../Field/validate";

const FileUploaderModel = ({ isOpen, toggle, callback, name, isVideo }) => {
  const [isValidFile, setIsValidFile] = useState(true);
  const isLoadingFile = useSelector((state) => state.programs.isLoadingFile);
  const validateUploadFile = (file) => {
    const fileSize = file?.size / (1024 * 1024);
    let validFile = true;
    if (isVideo) {
      if (file.type !== "video/mp4" || fileSize > 50) {
        validFile = false;
      }
    } else {
      const allowFileTypes = ["image/jpeg", "image/png"];
      if (!allowFileTypes.includes(file.type) || fileSize > 5) {
        validFile = false;
      }
    }
    setIsValidFile(validFile);
    return validFile;
  };
  return (
    <ConfirmationModel
      showActions={false}
      maxWidth="sm"
      isOpen={isOpen}
      title={isVideo ? "Modifier la video" : "Modifier la vignette"}
      body={
        <Stack
          textAlign="center"
          sx={{
            background: isLoadingFile ? "rgba(0, 0, 0, 0.5)" : "#F1F1F1",
            padding: { xs: "10px", sm: "50px" },
            borderRadius: "12px",
          }}
          onClick={() => {
            if (!isLoadingFile) {
              document.querySelector("#file_uploader").click();
            }
          }}
        >
          {isLoadingFile && (
            <CircularProgress
              color="primary"
              sx={{ color: "white", margin: "auto" }}
            />
          )}
          <Box sx={{ display: isLoadingFile ? "none" : "" }}>
            <Field
              type="file"
              name={name ? name + "1" : "file1"}
              accept={
                isVideo
                  ? "video/mp4,video/x-m4v,video/*"
                  : "image/png, image/jpeg"
              }
              style={{ visibility: "hidden" }}
              id="file_uploader"
              multiple={false}
              onChange={(e) => {
                if (validateUploadFile(e.target.files[0])) {
                  callback(e.target.files[0]);
                }
              }}
              validate={required}
            />
            <img src="/assets/logo/upload.svg" alt="upload" height="24px" />
            <Typography color="#7742E9" fontSize="14px" fontWeight="600" mt={1}>
              TÉLÉVERSER UN FICHIER
            </Typography>
            <Typography color="#999999" fontSize="12px" fontWeight="500" mt={1}>
              ou faites glisser et déposez le fichier ici
            </Typography>
            <Typography
              color={isValidFile ? "#999999" : "#B72136"}
              fontSize="12px"
              fontWeight="500"
              mt={1}
            >
              {isVideo
                ? "Uniquement les fichiers MP4 jusqu'à 50Mo."
                : "Uniquement les fichiers JPEG ou PNG jusqu'à 5MB."}
            </Typography>
          </Box>
        </Stack>
      }
      handleClose={toggle}
    />
  );
};

export default FileUploaderModel;
