import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Grid, IconButton, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanAllProgramData, getInitialProgram } from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #CCCCCC",
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    fontWeight: 500,
    fontSize: "12px",
  },
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const SessionListBody = ({ toggleDeleteModel, session }) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const classes = useStyle();
  const { type } = useSelector((state) => state.user.userDetails);
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => {
        dispatch(cleanAllProgramData());
        dispatch(getInitialProgram());
        naviagate(`/fitness/sessions/${session._id}`);
      }}
    >
      <Grid container>
        <Grid
          item
          sm={7}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <img
            className={classes.img}
            src={session.thumbnail}
            alt="profile"
            onError={(event) => {
              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
              event.onerror = null;
            }}
          />
          {session.name}
        </Grid>
        <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
          {session.in_explorer ? "Yes" : "No"}
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => naviagate(`/fitness/sessions/${session._id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(session);
            }}
          >
            <DeleteOutlineIcon sx={{ fontSize: "28px" }} />
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default SessionListBody;
