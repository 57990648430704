import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "layout/DashboardSidebar";
import DashboardNavbar from "layout/DashboardNavbar";

const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
    height: "100%",
}));
// ----------------------------------------------------------------------

export default function Dashboard() {
    const [open, setOpen] = React.useState(false);
    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
            />
            <Outlet />
        </RootStyle>
    );
}
