export const adminConfig = [
  {
    title: "ACCUEIL",
    path: "/",
    icon: <img src="/assets/logo/home.svg" alt="home" />,
    references: ["/"],
  },
  {
    title: "MY CLIENTS",
    path: "/clients",
    icon: <img src="/assets/logo/users.svg" alt="My Client" />,
    references: ["/clients"],
  },
  {
    title: "FITNESS",
    path: "/fitness/exercices",
    icon: <img src="/assets/logo/fire.svg" alt="fire" />,
    references: [
      "/fitness/exercices",
      "/fitness/sessions",
      "/fitness/programs",
    ],
    children: [
      {
        title: "Exercices",
        path: "/fitness/exercices",
        references: [
          "/fitness/exercices/1",
          "/fitness/exercices",
          "/fitness/exercices/new",
        ],
      },
      {
        title: "Sessions",
        path: "/fitness/sessions",
        references: [
          "/fitness/sessions/1",
          "/fitness/sessions",
          "/fitness/sessions/create",
        ],
      },
      {
        title: "Programmes",
        path: "/fitness/programs",
        references: [
          `/fitness/programs/1`,
          `/fitness/programs`,
          `/fitness/programs/final`,
          `/fitness/programs/create`,
        ],
      },
    ],
  },
  {
    title: "NUTRITION",
    icon: <img src="/assets/logo/sticksIcon.svg" alt="fire" />,
    references: ["/nutrition/recipes", "/nutrition/programs"],
    children: [
      {
        title: "Recettes",
        path: "/nutrition/recipes",
        references: [
          "/nutrition/recipes/1",
          "/nutrition/recipes",
          "/nutrition/recipes/create",
        ],
      },
      {
        title: "Programmes",
        path: "/nutrition/programs",
        references: [
          "/nutrition/programs/create",
          "/nutrition/programs",
          "/nutrition/programs/final",
        ],
      },
    ],
  },
];
