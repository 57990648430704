import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Alert,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate, fullName } from "utils/common";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #CCCCCC",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    fontWeight: 500,
    fontSize: "12px",
  },
}));

const Status = styled(Typography)(({ theme }) => ({
  background: theme.palette.gradients.primary,
  fontSize: "12px",
  padding: "4px 12px",
  color: "#FFFFFF",
  borderRadius: "44px",
  "&.inactive": {
    background: "#D40000",
  },
}));

const ClientListBody = ({ toggleDeleteModel, client }) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const { type } = useSelector((state) => state.user.userDetails);
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => naviagate(`/clients/${client._id}`)}
    >
      <Grid container>
        <Grid
          item
          sm={3}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {fullName(client.first_name, client.last_name)}
        </Grid>
        <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={client.email} arrow>
            <Typography
              mr={2}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100%",
              }}
            >
              {client.email}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
          {client.subscription ? client.subscription.name : "-"}
        </Grid>
        <Grid
          item
          sm={2}
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {formatDate(client.created_at)}
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", alignItems: "center" }}>
          <Status
            className={client.status === "INACTIF" ? "inactive" : ""}
            component="span"
          >
            {client.status}
          </Status>
        </Grid>
        <Grid item sm={1} sx={{ textAlign: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => naviagate(`/clients/${client._id}`)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          {type === "ADMIN" && (
            <IconButton
              color="info.main"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                toggleDeleteModel(client);
              }}
            >
              <DeleteOutlineIcon sx={{ fontSize: "28px" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default ClientListBody;
